import React, { useContext, useState, useEffect } from "react";
import { getData } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";

const providername = 'OrgfunctionProvider';
const servicename = 'orgfunctions';
const OrgfunctionContext = React.createContext();

export function useOrgfunctionList() {
	return useContext(OrgfunctionContext);
}

export function OrgfunctionProvider({ children }) {
	const [orgfunctionList, setOrgfunctionList] = useState([]);

	useEffect(() => {
		logContext(providername, 'info', `########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			logContext(providername, 'info', `########### ${providername} list: ${JSON.stringify(_list)}`)
			setOrgfunctionList(_list);
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}, [])

	return (
		<OrgfunctionContext.Provider value={orgfunctionList}>
			{children}
		</OrgfunctionContext.Provider>
	)
}