import React, { useContext, useState, useEffect } from "react";
import { findData, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCheckRole } from "../Persistence/ErrorContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import { startOfDay } from "date-fns";

const providername = 'Graph24hProvider';
const servicename = ENUM_SERVICES.GRAPH24H;
const listenernames = ['patched'];
const Graph24hContext = React.createContext();
const Graph24hUpdateContext = React.createContext();

export function useGraph24h() {
	return useContext(Graph24hContext);
}
export function useGraph24hUpdate() {
	return useContext(Graph24hUpdateContext);
}

export function Graph24hProvider({ children }) {
	const checkRole = useCheckRole();
	const [graph24h, setGraph24h] = useState();
	const [graph24hChanged, setGraph24hChanged] = useState();
	const [graph24hDate, setGraph24hDate] = useState()
	const [mounted, setMounted] = useState(false);

	const graph24hUpdate = (date) => {
		const timezoneOffset = date.getTimezoneOffset() * 60 * 1000
		setGraph24hDate(startOfDay(date.getTime()) === startOfDay(Date.now()) ? undefined : date.getTime() - timezoneOffset)
	}

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		logContext(providername, 'info', 'Graph24h changed listItemChanged', graph24hChanged);
		if (graph24hChanged) {
			if (graph24hChanged.startDay === startOfDay(Date.now())) {

			}
			setGraph24h(graph24hChanged)
			setGraph24hChanged(null)
		}
	}, [graph24hChanged])

	useEffect(() => {
		logContext(providername, 'info', `########### useEffect`);
		if (mounted) {
			checkRole(ENUM_ROLES.GRAPH24H).then((check) => {
				if (check) {
					findData(servicename, { date: graph24hDate }).then(async (graph24h) => {
						//logContext(providername, 'info', `########### Graph24h: ${JSON.stringify(graph24h)}`)
						logContext(providername, 'info', `########### Graph24h:`, graph24h)
						setGraph24h(graph24h);
						updateServiceListener(servicename, listenernames, (forceMonitor) => setGraph24hChanged(forceMonitor));
					}).catch((error) => { LoggerContext.error("Error " + providername, error) });
				}
			})
		}
	}, [mounted, graph24hDate])

	return (
		<Graph24hContext.Provider value={graph24h}>
			<Graph24hUpdateContext.Provider value={graph24hUpdate}>
				{children}
			</Graph24hUpdateContext.Provider>
		</Graph24hContext.Provider>
	)
}