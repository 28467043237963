import React, { useState, useEffect, useRef, useCallback } from 'react';
import FullCalendar from "@fullcalendar/react";
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import { Toast } from 'primereact/toast';
import { add, format, isAfter, isBefore } from 'date-fns';
import { Button } from 'primereact/button';
import logger, { logDefault } from '../Utils/logger';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useUserSelectionList } from '../Persistence/UserContext';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import client, { findData } from '../feathers';
import { updateStandByEntry } from '../Manager/TimenetriesManager';
import { DELAY, timeEntryCategoryId, useCalendarEvDList, useCalendarEvDupdate } from '../Persistence/CalendarEVDContext';
import { ENUM_ROLES } from '../Enums/ENUM_ROLES';
import { useErrorUpdate } from '../Persistence/ErrorContext';

const CLASSNAME = 'CalendarEVD'


let selectedEvent = null;
let selectStart = null;
let selectEnd = null;
let rendered = false;
let oneClickTimer;
let clickCnt = 0;

const handleSelect = (start, end) => {
	selectStart = start
	selectEnd = end
	if (selectedEvent) {
		selectedEvent.setProp('borderColor', 'black');
		selectedEvent.setProp('backgroundColor', selectedEvent.extendedProps.backgroundColor);
		selectedEvent = null;
	}
	logDefault(CLASSNAME, 'info', "select:" + format(selectStart, 'yyyy-MM-dd HH:mm') + " - " + format(selectEnd, 'yyyy-MM-dd HH:mm'));
}

export const CalendarEVD = () => {
	const userList = useUserSelectionList();
	const events = useCalendarEvDList()
	const calendarEvDUpdate = useCalendarEvDupdate();
	const errorUpdate = useErrorUpdate();
	const [personsList, setPersonsList] = useState()
	const [mounted, setMounted] = useState(false)
	const toast = useRef(null);
	const calendar = useRef(null);
	const currentUser = useCurrentUser();

	useEffect(() => {
		if (!currentUser.roles.find((e) => e.alias === ENUM_ROLES.CALENDAREVD)) {
			errorUpdate('Keine Berechtigung!', 'Sie haben keine Berechtigung für diesen Bereich!\nEVD-Kalender')
		}
	}, [currentUser])

	useEffect(() => {
		if (userList && userList.length > 0) {
			const persons = userList.filter((e) => { return e.orgfunctions.find((f) => f.alias === 'EVD') })
			setPersonsList(persons);
			setMounted(true)
		} else if (userList) {
			setMounted(true)
		}
	}, [userList])

	const getStandbyEntries = async (info, successCallback, failureCallback) => {
		if (rendered) {
			const list = await calendarEvDUpdate(info.start.getTime(), info.end.getTime())
			rendered = false;
			if (successCallback) {
				successCallback([])
			}
		} else {
			rendered = true;
			if (successCallback) {
				successCallback([])
			}
		}
	}

	const createCalendarEntry = useCallback(async (timeentryUserId) => {
		if (isAfter(Date.now(), selectEnd)) {
			toast.current.show({ severity: 'error', summary: 'Fehler: Ende Datum', detail: 'Ende Datum liegt in der Vergangenheit', life: 2000 });
		} else if (selectStart && selectEnd && timeentryUserId) {
			await updateStandByEntry(timeEntryCategoryId, selectStart, selectEnd, timeentryUserId);
			selectedEvent = null;
		} else {
			toast.current.show({ severity: 'error', summary: 'Fehler', detail: 'Kein Eintrag oder neues Datum ausgewählt', life: 2000 });
		}
	}, [])

	const renderPersonButtons = () => {
		return <div className="flex flex-wrap  justify-content-center column-gap-2 row-gap-2 mt-4">
			{(personsList || []).map(p => <Button key={'evdpersons-' + p.id} className='w-12rem justify-content-center' style={{ color: '#' + p.backcolor ? p.color : '#000000', backgroundColor: '#' + p.backcolor ? p.backcolor : p.color }} onClick={() => createCalendarEntry(p.id)} label={p.firstname.substring(0, 1) + '. ' + p.lastname} />)}
		</div>
	}

	return (!mounted ? <></> : <>
		<Toast ref={toast} position="bottom-left" />
		<div className="flex justify-content-center align-content-center md:p-4  w-full">
			<div className="flex calenderEVD justify-content-center flex-column">
				<div className="flex w-full" >
					<FullCalendar
						ref={calendar}
						height='600px'
						contentHeight='400px'
						weekNumbers={true}
						eventDisplay='block'
						eventBorderColor='rgb(255,255,255)'
						locales={[deLocale]}
						dayHeaderFormat={{ weekday: 'short', day: 'numeric', omitCommas: true }}
						slotLabelFormat={[{ hour: 'numeric' }]}
						firstDay={1}
						initialView='dayGridMonth'
						datesSet={getStandbyEntries}
						header={{ left: "prev,next", center: "title", right: "dayGridWeek,timeGridWeek,timeGridDay" }}
						themeSystem="Simplex"
						plugins={[dayGridPlugin, interactionPlugin]}
						displayEventTime={false}
						events={events}
						initialEvents={getStandbyEntries}
						eventClick={(info) => {
							clickCnt++;
							if (clickCnt === 1) {
								logDefault(CLASSNAME, 'info', 'eventClick:' + info.event.backgroundColor)
								handleSelect(info.event.start.getTime(), info.event.end.getTime())
								info.event.setProp('borderColor', 'red');
								info.event.setExtendedProp('backgroundColor', info.event.backgroundColor);
								selectedEvent = info.event
								oneClickTimer = setTimeout(() => clickCnt = 0, 400);
							} else if (clickCnt === 2) {
								clearTimeout(oneClickTimer);
								clickCnt = 0;
							}
						}}
						selectable={true}
						select={(info) => handleSelect(info.start.getTime(), info.end.getTime())}
						editable={true}
					/>
				</div>
				{renderPersonButtons()}
			</div>
		</div>
	</>
	);
}