import React, { useContext, useState, useEffect } from "react";
import { getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";


const providername = 'CompaniesProvider';
const servicename = 'companies';
const CompaniesContext = React.createContext();

export function useCompaniesList() {
	return useContext(CompaniesContext);
}

export function CompaniesProvider({ children }) {
	const [companiesList, setCompaniesList] = useState([]);

	useEffect(() => {
		LoggerContext.debug(`########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			//console.debug(`########### ${providername} list: ${JSON.stringify(_list)}`)
			setCompaniesList(_list);
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}, [])

	return (
		<CompaniesContext.Provider value={companiesList}>
			{children}
		</CompaniesContext.Provider>
	)
}