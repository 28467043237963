import React, { useContext, useState, useEffect } from "react";
import { getData, patchData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCheckRole } from "./ErrorContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";

const providername = 'GuardbooksProvider';
const servicename = ENUM_SERVICES.GUARDBOOKS;
const listenernames = ['created', 'patched'];
const GuardbooksContext = React.createContext();

export function useGuardbooks() {
	return useContext(GuardbooksContext);
}

export function GuardbooksProvider({ children }) {
	const checkRole = useCheckRole();
	const [guardbooks, setGuardbooks] = useState();
	const [guardbookChanged, setGuardbookChanged] = useState();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		logContext(providername, 'info', 'Guardbooks changed listItemChanged', guardbookChanged);
		if (guardbookChanged) {
			setGuardbooks(guardbookChanged)
			setGuardbookChanged(null)
		}
	}, [guardbookChanged])

	useEffect(() => {
		logContext(providername, 'info', `########### ${providername} useEffect`);
		if (mounted) {
			checkRole(ENUM_ROLES.GUARDBOOKS).then((check) => {
				if (check) {
					getData(servicename).then(async (guardbook) => {
						logContext(providername, 'debug', `########### ${providername} Guardbook: ${JSON.stringify(guardbook)}`)
						setGuardbooks(guardbook);
						updateServiceListener(servicename, listenernames, (guardbook) => setGuardbookChanged(guardbook));
					}).catch((error) => { LoggerContext.error("Error " + providername, error) });
				}
			})

		}
	}, [mounted])

	return (
		<GuardbooksContext.Provider value={guardbooks}>
			{children}
		</GuardbooksContext.Provider>
	)
}