import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { patchData } from '../feathers';
import { ChangePassword } from "../Login/ChangePassword";
import { useCurrentUser, useCurrentUserUpdate } from "../Persistence/CurrentUserContext"
import { emptyUser, UserForm, validateUser } from './UserForm';
import { InputChangesChecked } from '../Components/FormElements/InputChangesChecked';
import { DropdownChangesChecked } from '../Components/FormElements/DropdownChangesChecked';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { InputTextarea } from 'primereact/inputtextarea';

export const UserProfile = () => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const updateCurrentuser = useCurrentUserUpdate();
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
	const [displayUserDialog, setDisplayUserDialog] = useState(false);

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	useEffect(() => {
		if (currentUser) {
			currentUser.companyName = currentUser.company.name
			formikUser.setValues({ ...currentUser })
		}
	}, [currentUser])

	const handleChangePassword = () => {
		setDisplayPasswordDialog(true);
	}
	const handleEditUser = () => {
		setDisplayUserDialog(true)
	}

	const patchUser = async () => {
		const { id, active, version, mobile, phone, phoneBusiness, firstname, lastname } = formikUser.values
		await patchData('users', { id, version, active, mobile, phone, phoneBusiness, firstname, lastname, }).then((user) => {
			updateCurrentuser(user);
			formikUser.setValues(user)
			setDisplayUserDialog(false);
		})
	}


	return (
		<div className='flex h-full my-auto '>
			<Dialog header={'Passwort ändern: ' + currentUser.displayname} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={true} hideDialog={setDisplayPasswordDialog} />
			</Dialog>
			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header='Eigene Daten bearbeiten'
				message='Hier können sie Ihre eigenen Daten ändern.'
				setDisplayDialog={setDisplayUserDialog}
				displayRegionDialog={displayUserDialog}
			/>
			<div className='flex w-full justify-content-center align-items-center mr-2'>
				<div className='flex flex-column p-3 userprofile'>
					<div className='flex justify-content-center m-2 text-4xl font-semibold'>
						{currentUser.firstname + " " + currentUser.lastname}
					</div>
					<div className="flex formgrid grid">
						<InputChangesChecked disabled={true} id='firstname' autoFocus={true} type="text" formik={formikUser} label='Vorname' />
						<InputChangesChecked disabled={true} id='lastname' tabIndex={1} type="text" formik={formikUser} label='Nachname' />
					</div>
					<div className="flex formgrid grid">
						<InputChangesChecked disabled={true} id='phone' tabIndex={2} type="text" formik={formikUser} label='Telefon' />
						<InputChangesChecked disabled={true} id='phoneBusiness' tabIndex={2} type="text" formik={formikUser} label='Telefon (gesch.)' />
					</div>
					<div className="flex formgrid grid">
						<InputChangesChecked disabled={true} id='mobile' tabIndex={3} type="text" formik={formikUser} label='Mobil' />
						<InputChangesChecked disabled={true} id='companyName' tabIndex={3} type="text" formik={formikUser} label='Firma' />
					</div>
					<div className="flex formgrid grid">
						<div className="field col">
							<label htmlFor='orgfunctions' className='block'>Funktionen</label>
							<div className='userprofilelist'>{currentUser.orgfunctions.map(e => (<div>{e.display}</div>))}</div>
						</div>
						<div className="field col">
							<label htmlFor='qualifications' className='block'>Qualifikationen</label>
							<div className='userprofilelist'>{currentUser.qualifications.map(e => (<div>{e.display}</div>))}</div>
						</div>
					</div>
					<div className="flex justify-content-between w-full">
						<Button onClick={handleChangePassword} icon={ENUM_ICONS.USER} className='flex w-9rem md:w-10rem text-white ' style={{ backgroundColor: '#494C4F' }} label='Passwort ändern' />
						<Button onClick={handleEditUser} icon={ENUM_ICONS.PENCIL} className='flex w-9rem  md:w-10rem text-white ' style={{ backgroundColor: '#494C4F' }} label='Bearbeiten' />
					</div>

				</div>
			</div>
		</div>
	)
}