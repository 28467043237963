import { useEffect } from 'react';
import { Login } from './Login/login';
import { logout } from './feathers';
import { ChangePassword } from './Login/ChangePassword';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { CurrentUserProvider, useCurrentUser } from './Persistence/CurrentUserContext';
import { LoginProvider } from './Persistence/LoginContext';
import { useSocketError } from './Persistence/SocketErrorContext';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import logger, { logDefault } from './Utils/logger';
import { NavigationPage } from './Navigation/NavigationPage';
import { locale, addLocale } from 'primereact/api';
import { BreadcrumbsProvider } from './Persistence/Breadcrumbs';
import { DataViewFilterProvider } from './Persistence/DataViewFilterContext';
import { ConfigurationsProvider } from './Persistence/ConfigurationsContext';
import { QualificationProvider } from './Persistence/QualificationContext';
import { OrgfunctionProvider } from './Persistence/OrgfunctionContext';
import { RoleProvider } from './Persistence/RoleContext';
import { CompaniesProvider } from './Persistence/CompaniesContext';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { ErrorProvider } from './Persistence/ErrorContext';
import { UserProvider } from './Persistence/UserContext';

const CLASSNAME = 'Application'
export const Application = () => {
	const currentUser = useCurrentUser();
	const socketError = useSocketError();

	useEffect(() => {
		window.onunhandledrejection = event => {
			logger.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
		};
		addLocale('de', {
			firstDayOfWeek: 1,
			dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
			monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			today: 'Tag',
			clear: 'Löschen'
		});
		locale('de')
	}, [])

	const renderPage = () => {
		logDefault(CLASSNAME, 'info', "------------ APPLICATION -------------", currentUser);
		if (socketError !== null) {
			return <div className="flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }}>
				<div className='shadow-24 align-self-center ' style={{ width: '400px' }}>
					<Message className='p-6' severity="warn" text={socketError} />
				</div>
			</div>
		} else if (currentUser && currentUser.forceChangePassword === "1") {
			return (
				<Dialog header={'Passwort ändern: ' + currentUser.displayname} visible={true} style={{ width: '400px' }} onHide={() => logout()}>
					<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} />
				</Dialog>
			)
		} else if (currentUser) {
			return <PrimeReactProvider>
				<DataViewFilterProvider>
					<ErrorProvider>
						<BreadcrumbsProvider>
							<CompaniesProvider>
								<QualificationProvider>
									<OrgfunctionProvider>
										<RoleProvider>
											<ConfigurationsProvider>
												<UserProvider>
													<NavigationPage />
												</UserProvider>
											</ConfigurationsProvider>
										</RoleProvider>
									</OrgfunctionProvider>
								</QualificationProvider>
							</CompaniesProvider>
						</BreadcrumbsProvider>
					</ErrorProvider>
				</DataViewFilterProvider >
			</PrimeReactProvider>
		}
		return <Login />;
	}

	return <div className='application'>{renderPage()}</div>
}
