export enum ENUM_SERVICES {
	COMPANIES = 'companies',
	GUARDBOOKS = 'guardbooks',
	QUALIFICATIONS = 'qualifications',
	ORGFUNCTIONS = 'orgfunctions',
	USERS = 'users',
	ADHOCS = 'adhocs',
	TIMEENTRIES = 'timeentries',
	ROLES = 'roles',
	ADHOCSTATUS = 'adhoc-status',
	FORCEMONITOR = 'force-monitor',
	GRAPH24H = 'graph24h'
};