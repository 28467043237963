import React, { useContext, useState, useEffect } from "react";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { getData } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { reactLocalStorage } from 'reactjs-localstorage';
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useRoleList } from "./RoleContext";
import { OrgfunctionProvider, useOrgfunctionList } from "./OrgfunctionContext";
import { QualificationProvider, useQualificationList } from "./QualificationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompaniesProvider, useCompaniesList } from "./CompaniesContext";

const providername = 'ConfigurationsProvider';

const ConfigurationsContext = React.createContext();

const ServiceList = [
	{ icon: ENUM_ICONS.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: ENUM_SERVICES.COMPANIES },

]
const StatusActiveList = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', datatable: 'Aktiv', alias: 1, color: 'green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', datatable: 'Inaktiv', alias: 0, color: 'grey' }];
const AdhocStatusList = [
	{ awesomeIcon: ENUM_AWESOMEICON.ONLINE, value: 1, onlyIcon: true, display: 'Online', datatable: 'Online', alias: 1, color: 'green' },
	{ awesomeIcon: ENUM_AWESOMEICON.OFFLINE, value: 0, onlyIcon: true, display: 'Offline', datatable: 'Offline', alias: 0, color: 'grey' }
];

export function useConfigurationsList() {
	return useContext(ConfigurationsContext);
}

export function ConfigurationsProvider({ children }) {
	const rolesList = useRoleList();
	const orgfunctionsList = useOrgfunctionList();
	const qualificationsList = useQualificationList();
	const companiesList = useCompaniesList();
	const [enumsList, setConfigurationsList] = useState({ AdhocStatusList, StatusActiveList, ServiceList, RolesList: [] });

	useEffect(() => {

		const _list = {}
		_list.AdhocStatusList = AdhocStatusList;
		_list.StatusActiveList = StatusActiveList;
		_list.ServiceList = ServiceList;
		_list.RolesList = rolesList
		_list.QualificationsList = qualificationsList
		_list.OrgfunctionsList = orgfunctionsList
		_list.CompaniesList = companiesList
		setConfigurationsList(_list);
		logContext(providername, 'info', `########### ${providername} useEffect found:${Object.getOwnPropertyNames(_list).length}`)



	}, [rolesList, orgfunctionsList, qualificationsList, companiesList])

	return (

		<ConfigurationsContext.Provider value={enumsList}>
			{children}
		</ConfigurationsContext.Provider>

	)
}