import React, { useContext, useState, useEffect } from "react";
import { getData, patchData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCurrentUser } from "./CurrentUserContext";
import { useNavigate } from "react-router-dom";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import { ENUM_ROUTES } from "../Navigation/Routes";

const providername = 'AdhocStatusProvider';
const servicename = ENUM_SERVICES.ADHOCSTATUS;
const listenernames = ['created', 'patched'];
const AdhocStatusContext = React.createContext();
const AdhocStatusCurrentUserContext = React.createContext();

export function useAdhocStatusList() {
	return useContext(AdhocStatusContext);
}
export function useAdhocStatusCurrentUser() {
	return useContext(AdhocStatusCurrentUserContext);
}

export function AdhocStatusProvider({ children }) {
	const currentUser = useCurrentUser();
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false);
	const [adhocStatusCurrentUser, setAdhocStatusCurrentUser] = useState(null)
	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser) {
			setMounted(true)
		}
	}, [currentUser])

	useEffect(() => {
		logContext(providername, 'info', 'AdhocStatus changed listItemChanged', listItemChanged);
		if (listItemChanged) {
			if (listItemChanged.adhocStatusUserId === currentUser.id) {
				setAdhocStatusCurrentUser(listItemChanged)
			}
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false);
		}
	}, [listItemChanged])

	useEffect(() => {
		logContext(providername, 'info', `########### ${providername} useEffect`);
		if (mounted) {
			if (!currentUser.isEmployee) {
				for (let role of [ENUM_ROLES.FORCEMONITOR, ENUM_ROLES.GRAPH24H, ENUM_ROLES.CALENDAREVD, ENUM_ROLES.USERS]) {
					if (currentUser.roles.find(e => e.alias === role)) {
						navigate('/' + ENUM_ROUTES[role]);
						break;
					}
				}

			} else {

			}
			getData(servicename).then(async (_list) => {
				logContext(providername, 'info', `########### ${providername} list: ${_list.length}`);
				logContext(providername, 'debug', `########### ${providername} list: ${JSON.stringify(_list)}`);
				/** AdhocStatus des angemeldeten Users setzen */
				let adhocStatusCurrentUser = _list.find(e => e.adhocStatusUserId === currentUser.id);
				if (!adhocStatusCurrentUser) {
					patchData(ENUM_SERVICES.ADHOCSTATUS, { adhocStatusUserId: currentUser.id }).then(adhocStatus => {
						setAdhocStatusCurrentUser(adhocStatus)
					})
				} else {
					setAdhocStatusCurrentUser(adhocStatusCurrentUser)
				}

				setList(_list);
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		}
	}, [mounted])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	return (
		<AdhocStatusCurrentUserContext.Provider value={adhocStatusCurrentUser}>
			<AdhocStatusContext.Provider value={list}>
				{children}
			</AdhocStatusContext.Provider>
		</AdhocStatusCurrentUserContext.Provider>
	)
}