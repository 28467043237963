import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useRef, useState } from 'react';
import { useUserResponsibleList } from '../../Persistence/UserContext';
import { logDefault } from '../../Utils/logger';

const CLASSNAME = 'ResponsibleMultiSelect';
export const ResponsibleMultiSelect = ({ value, onChange, className }) => {

	const userList = useUserResponsibleList();
	const [options, setOptions] = useState();
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (userList) {
			logDefault(CLASSNAME, 'info', 'list:' + userList.length, userList)
			const list = [{ id: 0, displayName: 'nicht gesetzt', lastname: 'n.', firstname: 'n.' }]
			setOptions(list.concat(userList));
		}

	}, [userList])

	useEffect(() => {
		if (value) {
			setSelected(value)
		} else {
			setSelected([])
		}

	}, [value])

	const itemTemplate = (option) => {
		return option ? option.lastname + ', ' + option.firstname : ''
	}

	const selectedTemplate = (option) => {
		const user = (options || userList).find(e => e.id === option) || null
		return user ? <div className="flex text-xm ">{user.lastname + ', ' + user.firstname}</div> : '';
	}

	const onSelectionChange = (e) => {
		setSelected(e.value);
		onChange(e.value)
	}

	const panelFooterTemplate = () => {
		const selectedItems = selected;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> Berater*In ausgewählt.
			</div>
		);
	}

	return (<>
		{(!userList || userList.length === 0) ? 'keine userlist' :
			<MultiSelect
				value={selected}
				options={options}
				optionValue='id'
				onChange={onSelectionChange}
				itemTemplate={itemTemplate}
				filter
				filterBy='lastname'
				selectedItemTemplate={selectedTemplate}
				className="multiselect-custom"
				panelFooterTemplate={panelFooterTemplate}
				placeholder='Berater*In wählen'
				selectedItemsLabel={(selected ? selected.length : 0) + ' Berater*In ausgewählt'}
				maxSelectedLabels={3} />
		}
	</>)
}