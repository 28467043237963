import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Application } from './Application';
import reportWebVitals from './reportWebVitals';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import './index.scss';
import { ENUM_ROUTES } from './Navigation/Routes';
import { UserAdministration } from './UserAdministration/UserAdministration';
import { LoginProvider } from './Persistence/LoginContext';
import { ErrorPage } from './Navigation/ErrorPage';
import { EmployeeStatus } from './Employee/EmployeeStatus';
import { Graph24h } from './OperativeViews/Graph24h';
import { AdhocStatusProvider } from './Persistence/AdhocStatusContext';
import { ForceMonitor } from './OperativeViews/ForceMonitor';
import { ForceMonitorProvider } from './Persistence/ForceMonitorContext';
import { CalendarEVD } from './OperativeViews/CalendarEVD';
import { CalendarEvDProvider } from './Persistence/CalendarEVDContext';
import { Graph24hProvider } from './Persistence/Graph24hContext';
import { UserProvider } from './Persistence/UserContext';
import { Guardbooks } from './OperativeViews/Guardbooks';
import { GuardbooksProvider } from './Persistence/GuardbooksContext';
import { UserProfile } from './UserAdministration/UserProfile';

Sentry.init({
	dsn: "https://5b5be9fff7024ca28b6d792760820bbf@sentry.bos-connect.de/4",
	tracesSampleRate: 1.0,
	environment: window.location.hostname,
	replaysSessionSampleRate: 0.0,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
});

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
/* root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
) */
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<CurrentUserProvider><LoginProvider><Application /></LoginProvider></CurrentUserProvider>}>
					<Route index element={<AdhocStatusProvider><EmployeeStatus /></AdhocStatusProvider>} />
					<Route path={`${ENUM_ROUTES.ERROR}`} element={<ErrorPage />} />
					<Route path={`${ENUM_ROUTES.USERS}`} element={<UserProvider><UserAdministration /></UserProvider>} />
					<Route path={`${ENUM_ROUTES.GRAPH24H}`} element={<Graph24hProvider><Graph24h /></Graph24hProvider>} />
					<Route path={`${ENUM_ROUTES.CALENDAREVD}`} element={<UserProvider><CalendarEvDProvider><CalendarEVD /></CalendarEvDProvider></UserProvider>} />
					<Route path={`${ENUM_ROUTES.FORCEMONITOR}`} element={<ForceMonitorProvider><ForceMonitor /></ForceMonitorProvider>} />
					<Route path={`${ENUM_ROUTES.EMPLOYEESTATUS}`} element={<AdhocStatusProvider><EmployeeStatus /></AdhocStatusProvider>} />
					<Route path={`${ENUM_ROUTES.USERPROFILE}`} element={<UserProfile />} />
					<Route path={`${ENUM_ROUTES.GUARDBOOKS}`} element={<GuardbooksProvider><Guardbooks /></GuardbooksProvider>} />
				</Route>

			</Routes>
		</BrowserRouter>
	</React.StrictMode >,
);


reportWebVitals();
