import React, { useContext, useState, useEffect } from "react";
import { findData, getData, patchData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCurrentUser } from "./CurrentUserContext";
import { useUserList } from "./UserContext";

export const DELAY = 8 * 60 * 60 * 1000;
export const timeEntryCategoryId = 1;
const providername = 'CalendarEvDProvider';
const servicename = ENUM_SERVICES.TIMEENTRIES;
const listenernames = ['created', 'patched'];
const CalendarEvDContext = React.createContext();
const CalendarEvDUpdateContext = React.createContext();

export function useCalendarEvDList() {
	return useContext(CalendarEvDContext);
}
export function useCalendarEvDupdate() {
	return useContext(CalendarEvDUpdateContext);
}

export function CalendarEvDProvider({ children }) {
	const userList = useUserList();
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		logContext(providername, 'info', 'CalendarEvD changed listItemChanged', listItemChanged);
		if (listItemChanged) {
			const person = userList.find(e => e.id === listItemChanged.timeentryUserId)
			const backgroundColor = (person ? person.backcolor + 'AA' : 'red');
			const textColor = (person ? person.color : 'white');
			const title = person ? (person.firstname || '-').substring(0, 1) + '. ' + person.lastname : ' - ';
			const event = { id: listItemChanged.id, groupId: listItemChanged.id, backgroundColor, textColor, title, start: listItemChanged.start - DELAY, end: listItemChanged.end - DELAY }
			updateListEntryInProvider(providername, event, list, setList, servicename, false);
		}
	}, [listItemChanged])


	const updateCalendarEvDIntervall = async (start, end) => {
		if (mounted) {
			return findData(servicename, { timeEntryCategoryId, start: { $gte: start }, end: { $lte: end } }).then(async (list) => {
				logContext(providername, 'info', `########### ${providername} updateCalendarEvDIntervall list: ${list.length}`);
				const eventsList = []
				list.map((entry) => {
					const person = userList.find(e => e.id === entry.timeentryUserId)
					const backgroundColor = (person ? person.backcolor + 'AA' : 'red');
					const textColor = (person ? person.color : 'white');
					const title = person ? (person.firstname || '-').substring(0, 1) + '. ' + person.lastname : ' - ';
					eventsList.push({ id: entry.id, groupId: entry.id, backgroundColor, textColor, title, start: entry.start - DELAY, end: entry.end - DELAY })
				})
				setList(eventsList);
				return eventsList
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		}
	}

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	return (
		<CalendarEvDUpdateContext.Provider value={updateCalendarEvDIntervall}>
			<CalendarEvDContext.Provider value={list}>
				{children}
			</CalendarEvDContext.Provider>
		</CalendarEvDUpdateContext.Provider>
	)
}