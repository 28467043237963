import { useState } from 'react';
import client from '../feathers';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password'
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { updateLastAction } from '../Persistence/LoginContext';
import { LoggerLogin } from '../Utils/logger';

const INACTIVE_INTERVALL = 600;        // in seconds

export const Login = () => {

	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const [userInactiveIntervall, setUserInactiveIntervall] = useState(INACTIVE_INTERVALL / 60);
	const [keepAlive, setKeepAlive] = useState(false);
	const [error, setError] = useState('');

	const loginUser = () => {
		setError('')
		LoggerLogin.debug('userInactiveIntervall: ' + userInactiveIntervall);
		reactLocalStorage.set('keepAlive', keepAlive);
		reactLocalStorage.set('userInactiveIntervall', userInactiveIntervall);
		updateLastAction();
		var loginParams = keepAlive ? { strategy: 'local', username, password } : { strategy: 'local', username, password, expiresIn: userInactiveIntervall + 'M' }
		return client.authenticate(loginParams).catch(error => {
			LoggerLogin.error("ERROR login fehlgeschlagen: " + error.message);
			loginParams = keepAlive ? { strategy: 'email', email: username, password } : { strategy: 'email', email: username, password, expiresIn: userInactiveIntervall + 'M' }
			return client.authenticate(loginParams).catch(error => {
				setError("Login fehlgeschlagen: " + error.message);
			});
		});
	}

	useEffect(() => {
		setUserInactiveIntervall(parseInt(reactLocalStorage.get('userInactiveIntervall', '' + INACTIVE_INTERVALL)) / 60);
		setKeepAlive(reactLocalStorage.get('keepAlive', 'false') === 'true');
	}, [])

	return <div id="login-container">

		<div className="flex justify-content-center" style={{ width: '100%', height: '600px' }}>
			<div className="col-fixed align-content-center  my-auto" style={{ width: '400px' }}>
				<Fieldset legend="Anmeldedaten">
					<div className="field" >
						<p style={{ color: 'red' }} >{error}</p>
					</div>
					<div className="d-flex justify-content-center" style={{ width: '100%' }}>
						<div className="col-fixed align-content-center" style={{ width: '300px' }}>
							<div className="field" >
								<label htmlFor="mail" className="p-0" style={{ width: '200px' }} >Anmeldenamen</label>
								<InputText autoComplete="nope" placeholder="Anmeldenamen" style={{ width: '300px' }} id="mail" className={' ' + (username === '' ? 'p-invalid' : '')} onChange={ev => setUsername(ev.target.value)} />
							</div>
							<div className="field " >
								<label htmlFor="password" className="p-0" style={{ width: '200px' }}>Passwort</label>
								<Password autoComplete="nope" feedback={false} placeholder="Passwort" style={{ width: '300px' }} id="password" className={'' + (password === '' ? 'p-invalid' : '')} onChange={ev => setPassword(ev.target.value)} toggleMask />
							</div>
							<div className="flex ">
								<Checkbox style={{ marginRight: '10px' }} inputId="binary" checked={keepAlive} onChange={e => setKeepAlive(e.checked)} />
								<label htmlFor="binary">angemeldet bleiben</label>
							</div>
							<div className={!keepAlive ? "flex " : "hidden"}>
								<label className='flex my-auto'>Logout nach </label>
								<InputNumber autoComplete="nope" className={!keepAlive ? "col sm:inputtext" : "hidden"} id="userInactiveIntervall" value={userInactiveIntervall} onValueChange={(e) => setUserInactiveIntervall(e.value)} showButtons min={5} buttonLayout="horizontal" step={5} suffix=' min' />
								<label className='flex my-auto'>Inaktivität</label>
							</div>
							<div className="flex justify-content-center" style={{ marginTop: '30px' }}>
								<div className="field ">
									<Button className={'p-button-success'} icon="pi pi-sign-in" iconPos="right" onClick={() => loginUser()} label="Anmelden" />
								</div>
							</div>
						</div>
					</div>
				</Fieldset>
			</div>
		</div>
	</div>
}