import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from 'react';
import { Tag } from "primereact/tag";


export const MultiSelectChangesChecked = ({ formik, id, label, options, optionLabel, optionValue, maxSelectedLabels, placeholder, filterPlaceholder, className }) => {

	const itemTemplate = (option) => {
		return option ? option[optionLabel] : ''
	}

	const selectedTemplate = (option) => {
		return !option ? '' : <div className="flex text-xm ">{option[optionLabel]}</div>;
	}

	const onChange = (e) => {
		formik.handleBlur(e);
		formik.handleChange(e)
	}


	const panelFooterTemplate = () => {
		const selectedItems = formik.values[id];
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> Einträge ausgewählt.
			</div>
		);
	}

	return (<>
		<div className="field col">
			<label htmlFor={id} className='block'>{label}</label>

			<MultiSelect
				id={id}
				value={formik.values[id]}
				options={options}
				optionLabel={optionLabel}
				onChange={onChange}
				filterPlaceholder={filterPlaceholder}
				itemTemplate={itemTemplate}
				selectedItemTemplate={selectedTemplate}
				className={className + ' multiselect-custom'}
				panelFooterTemplate={panelFooterTemplate}
				placeholder={placeholder}
				selectedItemsLabel={(formik.values[id] ? formik.values[id].length : 0) + ' Einträge ausgewählt'}
				maxSelectedLabels={maxSelectedLabels} />

		</div>
	</>)

}