import React, { useContext, useState, useEffect } from "react";
import { getData, patchData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCheckRole } from "./ErrorContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";

const providername = 'ForceMonitorProvider';
const servicename = ENUM_SERVICES.FORCEMONITOR;
const listenernames = ['patched'];
const ForceMonitorContext = React.createContext();

export function useForceMonitor() {
	return useContext(ForceMonitorContext);
}

export function ForceMonitorProvider({ children }) {
	const checkRole = useCheckRole();
	const [forceMonitor, setForceMonitor] = useState();
	const [forceMonitorChanged, setForceMonitorChanged] = useState();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		logContext(providername, 'info', 'Force-Monitor changed listItemChanged', forceMonitorChanged);
		if (forceMonitorChanged) {
			setForceMonitor(forceMonitorChanged)
			setForceMonitorChanged(null)
		}
	}, [forceMonitorChanged])

	useEffect(() => {
		logContext(providername, 'info', `########### ${providername} useEffect`);
		if (mounted) {
			checkRole(ENUM_ROLES.FORCEMONITOR).then((check) => {
				if (check) {
					getData(servicename).then(async (forceMonitor) => {
						logContext(providername, 'debug', `########### ${providername} forceMonitor: ${JSON.stringify(forceMonitor)}`)
						setForceMonitor(forceMonitor);
						updateServiceListener(servicename, listenernames, (forceMonitor) => setForceMonitorChanged(forceMonitor));
					}).catch((error) => { LoggerContext.error("Error " + providername, error) });
				}
			})
		}
	}, [mounted])

	return (
		<ForceMonitorContext.Provider value={forceMonitor}>
			{children}
		</ForceMonitorContext.Provider>
	)
}