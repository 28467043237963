import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { stripSlashes } from '@feathersjs/commons';
import { PatchDataError, GetDataError, FindDataError } from './errorClasses'
import urlIO from './config.js'
import { reactLocalStorage } from 'reactjs-localstorage';
import { isBefore } from 'date-fns'
import logger, { LoggerContext, logDefault } from './Utils/logger';

console.log('feathers urlIO:' + urlIO)
const bcrypt = require('bcryptjs');

const socket = io(urlIO);
const client = feathers();


client.hooks({
	error(context) {
		logger.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack);
	},
	before: {
		all: [(context) => {
			const { app, path, method, app: { authentication: authService } } = context
			// bypass this for the actual auth service
			logger.debug("service: " + path + " method: " + method, app);
			if (stripSlashes(authService.options.path) === path && method === 'create') {
				return context
			}
			return context;
		}]
	}
})

client.configure(feathers.socketio(socket, {
	timeout: 5000
}));

client.configure(feathers.authentication({
	storage: window.localStorage,
	handleSocket: socket
}));



export const setupSocketErrorHandling = (errorCallback, successCallback) => {
	socket.removeAllListeners();
	socket.on('connect', successCallback);
	socket.on('error', errorCallback);
	socket.on('connect_error', errorCallback);
	socket.on('connect_failed', errorCallback);
	socket.on('disconnect', errorCallback);
}

export const checkPassword = (user, oldPassword) => {
	return bcrypt.compareSync(oldPassword, user.password); // true
}

export const logout = async () => {
	reactLocalStorage.remove('refreshToken');
	logger.debug("############### LOGOUT ###############")
	client.logout();
	return null;
}

export async function updateListEntryInProvider(providername, _listentry, origList, setUpdatedList, servicename, fetchNew) {
	if (!_listentry.refId || _listentry.refId === 0) {
		if (!_listentry) {
			logger.error(providername + " updateListEntryInProvider entry is nothing:");
			return;
		}

		if (fetchNew && _listentry.id) {
			_listentry = await getData(servicename, _listentry.id).catch((error) => {
			});
		}
		const _origList = origList.splice(0);
		var index = _origList.findIndex(e => e.id === _listentry.id);
		if (index === -1) {
			_origList.push(_listentry)
		} else {
			_origList[index] = _listentry;
		}
		logDefault(providername, 'info', 'updateListEntryInProvider:' + _origList.length)
		setUpdatedList(_origList);
		LoggerContext.debug(`${providername} updateListEntryInProvider index: ${index} id:${_listentry.id} listentries:${_origList.length}`);
	}
}

export function updateServiceListener(servicename, listenernames, handleListEntryChanged) {
	const service = client.service(servicename);
	listenernames.forEach((listener) => {
		service.removeListener(listener);
		if (handleListEntryChanged !== null) {
			service.on(listener, handleListEntryChanged);

		} else {
			logger.debug('CLEANUP ' + servicename);
		}
	})
	// service.on('created', (data) => console.log(servicename + " created in feathers ", data));
	// service.on('patched', (data) => console.log(servicename + " patched in feathers ", data));
}

export const getData = async (path, id) => {
	const service = client.service(path);
	logger.debug("getData " + path + " id:" + id);
	if (id) {
		return await service.get(id).catch((error) => {
			throw new GetDataError("id=" + id, error, path, id);
		});
	} else {
		return await service.find({ paginate: false }).then((result) => {
			return result.data ? result.data : result;
		}).catch((error) => {
			console.error(error.message, error)
			throw new FindDataError("", error, path)
		});
	}
}

export const createPassword = async (defaultPassword) => {
	var result = '';
	var characters = 'ABCDEFGHKLMNPQRSTUVWXYZ123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < 8; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return (defaultPassword ? 'test' : result);
}
export const findData = async (path, query) => {
	const service = client.service(path);
	const params = { query, paginate: false };
	return await service.find(params).then((result) => {
		return result.data ? result.data : result;

	}).catch((error) => {
		console.error(error.message, error)
		throw new FindDataError("", error, path)
	}
	);
}

export const patchData = async (path, data, user) => {
	const service = client.service(path);
	logger.info("patchdata:" + path);
	/** wenn keine id vorhanden ist, muss ein neuer Datensatz angelegt werden */
	if (!data.id) {
		return await service.create(data, user).then(async (newData) => {
			logger.debug("patchdata new Data:", newData)
			/** Datensatz neu holen, weil Assoziationen beim CREATE nicht vorhanden sind */
			return await getData(path, newData.id);
		}).catch((error) => {
			throw new PatchDataError(error.message, error, path, data)
		});
	} else {
		return await service.patch(data.id, data, user).catch((error) => {
			throw new PatchDataError(error.message, error, path, data)
		});
	}
}

export default client;