import { useState, useEffect } from 'react';
import client, { checkPassword, patchData, createPassword } from '../feathers';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password'
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import './ChangePassword.scss'

export const ChangePassword = ({ user, currentUser, hrMode, hideDialog }) => {

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [forceChangePassword, setForceChangePassword] = useState(false)
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		const generatePassword = async () => {
			const password = await createPassword(false);
			setNewPassword(password);
			setRepeatPassword(password);
		}

		if (hrMode) {
			setForceChangePassword(true);
			setShowPassword(true);
			generatePassword();
		}
	}, [hrMode])

	const changeUserPassword = async () => {
		setError('')
		if (!hrMode && !checkPassword(user, oldPassword)) {
			setError('bestehendes Passwort falsch')
		} else if (!hrMode && newPassword === oldPassword) {
			setError('neues Passwort darf NICHT gleich dem bestehenden Passwort sein')
		} else if (!hrMode && ("" + newPassword).toUpperCase() === ("" + oldPassword).toUpperCase()) {
			setError('neues Passwort muss neue Zeichenkombinationen besitzen')
		} else if (newPassword !== repeatPassword) {
			setError('Passwortwiederholung ist falsch!')
		} else if (("" + newPassword).length < 8) {
			setError('neues Passwort muss mindestens 8 Zeichen haben')
		} else {
			await patchData('users', { id: user.id, version: user.version, password: newPassword, forceChangePassword: forceChangePassword ? 1 : 0 }, currentUser).then(() => {
				if (hideDialog) { hideDialog(); }
				const confirm = () => {
					confirmDialog({
						message: (hrMode ? 'Passwort von ' + user.displayname + ' wurde geändert' : 'Bitte melden Sie sich mit dem neuen Passwort erneut an!'),
						header: 'Passwort geändert.',
						icon: 'pi pi-exclamation-triangle',
						rejectClassName: 'changepassword_confirmdialog_reject',
						acceptLabel: 'Ok',
						rejectLabel: null,
						accept: () => {
							if (!hrMode) { client.logout() }
						},
						onHide: () => { if (!hrMode) { client.logout() } }
					});
				}
				confirm();
			});
		}
	}

	return <div className="changePassword-container" id="fieldset_changePassword">
		<div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
			<div className="p-col-fixed p-as-center" >
				<div id='fieldset_changePassword'>
					<div className="p-field" >
						<p style={{ color: 'red' }} >{error}</p>
					</div>
					<div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
						<div className="p-fluid p-as-center">
							<div className="p-field " style={hrMode ? { display: 'none' } : {}}>
								<label htmlFor="oldPassword" className="p-p-0" >altes Passwort</label>
								<Password type='text' autoComplete="nope" feedback={false} placeholder="altes Passwort" id="oldPassword" className={'' + (oldPassword === '' || !oldPassword ? 'p-invalid' : '')} onChange={ev => setOldPassword(ev.target.value)} toggleMask />
							</div>
							<div className="p-field " >
								<label htmlFor="newPassword" className="p-p-0" >neues Passwort</label>
								<div className='p-password '>
									<span className={"p-input-icon-right " + (newPassword === '' || !newPassword ? 'p-invalid' : '')}>
										<i className={showPassword ? "pi pi-eye-slash" : "pi pi-eye"} onClick={() => { setShowPassword(!showPassword) }} />
										<InputText type={showPassword ? "text" : "password"} value={newPassword} placeholder="neues Passwort" id="newPassword" className={'' + (newPassword === '' || !newPassword ? 'p-invalid' : '')} onChange={ev => setNewPassword(ev.target.value)} />
									</span>
								</div>
							</div>
							<div className="p-field " >
								<label htmlFor="repeatPassword" className="p-p-0" >Passwort wiederholen</label>
								<Password autoComplete="nope" value={repeatPassword} feedback={false} placeholder="Passwort wiederholen" id="repeatPassword" className={'' + (repeatPassword === '' || !repeatPassword ? 'p-invalid' : '')} onChange={ev => setRepeatPassword(ev.target.value)} toggleMask />
							</div>
							<div className="p-field-checkbox p-mt-4" style={!hrMode ? { display: 'none' } : {}}>
								<Checkbox inputId="forceChangePasswordbinary" onChange={e => setForceChangePassword(e.checked)} checked={forceChangePassword} />
								<label htmlFor="forceChangePassword">{'Benutzer muss Passwort ändern'}</label>
							</div>
							<div className="p-d-flex p-jc-center" style={{ marginTop: '30px' }}>
								<div className="p-field ">
									<Button className={'p-button-success'} icon="pi pi-sign-in" iconPos="right" onClick={() => changeUserPassword()} label="Passwort ändern" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}