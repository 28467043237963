import { add, format, isBefore } from "date-fns";
import { logDefault } from "../Utils/logger";
import client from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { DELAY } from "../Persistence/CalendarEVDContext";

const CLASSNAME = 'TimeentriesManager'

export const updateStandByEntry = async (timeEntryCategoryId: number, start: number, end: number, timeentryUserId: number) => {
	const timeentriesService = client.service(ENUM_SERVICES.TIMEENTRIES);
	let startDate: Date = new Date(start + DELAY
	)
	do {
		await timeentriesService.find({ query: { start: startDate.getTime() } }).then(async (list: any[]) => {
			logDefault(CLASSNAME, 'info', 'updateTimeentry: ' + format(start, 'yyyy-MM-dd HH:mm') + " found:" + list.length, null);
			const standbyEnd: Date = add(startDate, { days: 1 })
			if (isBefore(Date.now(), standbyEnd)) {
				if (list.length === 0) {
					await timeentriesService.create({ timeEntryCategoryId, timeentryUserId, start: startDate.getTime(), end: standbyEnd.getTime() });
				} else {
					const entry = list[0];
					await timeentriesService.patch(entry.id, { ...entry, timeentryUserId });
				}
			}
		})
		startDate = add(startDate, { days: 1 })
	}
	while (isBefore(startDate, end));

}