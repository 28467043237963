import { ColorPicker } from "primereact/colorpicker";
import { classNames } from "primereact/utils"

export const ColorPickerChangesChecked = ({ id, label, formik, className, disabled, tabIndex, helptext, helptextcolor }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};
	const getFormHelpMessage = (name) => {
		return !isFormFieldValid(name) && helptext && <small style={{ color: helptextcolor }} className=" block" dangerouslySetInnerHTML={{ __html: helptext }} />;
	};

	const onChange = (e) => {
		formik.handleBlur(e);
		formik.handleChange(e)
	}

	return (
		<div className="field col">
			<label htmlFor={id} className='block'>{label}</label>
			<ColorPicker key={'colorpicker-' + id} tabIndex={tabIndex} id={id} name={id} disabled={disabled} value={formik.values[id]} onChange={onChange} className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className} />
			{getFormErrorMessage(id)}
			{getFormHelpMessage(id)}
		</div>
	)

}

