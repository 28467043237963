import { useEffect, useState } from 'react';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { logDefault } from '../Utils/logger';
import { InputChangesChecked } from '../Components/FormElements/InputChangesChecked';
import { DropdownChangesChecked } from '../Components/FormElements/DropdownChangesChecked';
import { MultiSelectChangesChecked } from '../Components/FormElements/MultiSelectChangesChecked';
import { ENUM_ROLES } from '../Enums/ENUM_ROLES';
import { ColorPicker } from 'primereact/colorpicker';
import { ColorPickerChangesChecked } from '../Components/FormElements/ColorPickerChangesChecked';
import { DatePickerChangesChecked } from '../Components/FormElements/DatePickerChangesChecked';

const CLASSNAME = 'UserForm'

export const emptyUser = {
	email: '', username: '', password: '', displayname: '', permissions: '', isAdmin: 0, loginAllowed: 1, orgfunctions: [], qualifications: [], roles: [], cutofftime: null,
	active: 1, version: 1, forceChangePassword: 1, lastname: '', firstname: '', mobile: '', phone: '', phoneBusiness: '', companyId: '', backcolor: 'ffffff', color: '000000', g26date: null
}

export const UserForm = ({ formik, profileMode }) => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values', formik.values)
	})

	return (
		!formik ? '' : <>
			<div className='col'>
				<div className="formgrid grid">
					<InputChangesChecked id='firstname' autoFocus={true} type="text" formik={formik} label='Vorname' />
					<InputChangesChecked id='lastname' tabIndex={1} type="text" formik={formik} label='Nachname' />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='phone' tabIndex={2} type="text" formik={formik} label='Telefon' />
					<InputChangesChecked id='phoneBusiness' tabIndex={2} type="text" formik={formik} label='Telefon (gesch.)' />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='mobile' tabIndex={3} type="text" formik={formik} label='Mobil' />
					<DropdownChangesChecked tabIndex={6} label={'Unternehmen'} id={'companyId'} editmode={currentUser.roles.find(e => e.alias === ENUM_ROLES.USERS)} optionValue='id' itemLabel={'display'} formik={formik} options={configurationsList.CompaniesList} handleChange={formik.handleChange} filter={false} />
				</div>
				{!currentUser.roles.find(e => e.alias === ENUM_ROLES.USERS) || profileMode ? '' :
					<>
						<div className="formgrid grid">
							<InputChangesChecked tabIndex={7} id='email' label='E-Mail' type="text" formik={formik} />
							<DatePickerChangesChecked id='g26date' label='G26 Prüfdatum' formik={formik} />
						</div>
						<div className="formgrid grid">
							<InputChangesChecked id='username' tabIndex={5} type="text" formik={formik} label='Anmeldename' />
							<DropdownChangesChecked tabIndex={8} className={'w-full'} editmode={currentUser.id !== formik.values.id} label='Benutzer kann sich anmelden' id={'loginAllowed'} itemLabel='display' formik={formik} options={[{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }]} filter={false} />
						</div>
						<div className="formgrid grid">
							<MultiSelectChangesChecked className='w-full' placeholder='Rollen selektieren' tabIndex={6} label={'Rollen'} id={'roles'} optionLabel='display' optionValue='id' editmode={true} itemLabel={'display'} formik={formik} options={configurationsList.RolesList} handleChange={formik.handleChange} filter={false} />
						</div>
						<div className="formgrid grid">
							<MultiSelectChangesChecked className='w-full' placeholder='Funktionen selektieren' tabIndex={6} label={'Org. Funktionen'} id={'orgfunctions'} optionLabel='display' optionValue='id' editmode={true} itemLabel={'display'} formik={formik} options={configurationsList.OrgfunctionsList} handleChange={formik.handleChange} filter={false} />
							<MultiSelectChangesChecked className='w-full' placeholder='Qualifikationen selektieren' tabIndex={6} label={'Qualifikationen'} id={'qualifications'} optionLabel='display' optionValue='id' editmode={true} itemLabel={'display'} formik={formik} options={configurationsList.QualificationsList} handleChange={formik.handleChange} filter={false} />
						</div>
						<div className={(formik.values.orgfunctions.find(e => e.alias === 'EVD') ? 'inline-flex' : 'hidden') + " formgrid grid"}>
							<div className='field col mr-3 flex'>
								<InputChangesChecked id='color' type="text" formik={formik} label='Textfarbe' />
								<ColorPickerChangesChecked id='color' label='Picker' formik={formik} />
							</div>
							<div className='field col mr-3 flex'>
								<InputChangesChecked id='backcolor' type="text" formik={formik} label='Hintergrund' />
								<ColorPickerChangesChecked id='backcolor' label='Picker' formik={formik} />
							</div>
						</div>
					</>}

				{formik.values.id ? '' :
					<div className="formgrid grid">
						<InputChangesChecked id='password' tabIndex={9} label='initiales Passwort' type="text" formik={formik} />
					</div>}
			</div>
		</>
	)
}

export const validateUser = (data) => {
	let errors = {};
	if (!data.email) {
		errors.email = 'E-Mail muss gesetzt und eindeutig sein';
	}
	if (!data.username) {
		errors.username = 'Anmeldenamen muss gesetzt und eindeutig sein';
	}
	if (!data.id && !data.password) {
		errors.password = 'Ein Passwort muss beim Anlegen gesetzt werden';
	}
	if (!data.companyId) {
		errors.companyId = 'Unternehmen muss ausgefüllt sein';
	}
	return errors;
}
