import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, Icon } from './Icons';

export const OverlayPanelMenu = ({ items }) => {
	const menu = useRef(null);

	return (<>
		<Menu model={items} popup ref={menu} id="popup_menu" />
		<div className='flex w-full justify-content-center ellipsis-icon cursor-pointer' onClick={(e) => menu.current.toggle(e)}>
			<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.ELLIPSIS} size={ENUM_AWESOMEICON_SIZE.XL} aria-controls="popup_menu" aria-haspopup />
		</div>

	</>
	)
}