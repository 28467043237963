import React, { useCallback, useContext, useEffect, useState } from "react";
import { logContext } from "../Utils/logger";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import { useCurrentUser } from "./CurrentUserContext";

const CLASSNAME = 'ErrorContext';
const ErrorContext = React.createContext();
const ErrorUpdateContext = React.createContext();
const CheckRoleContext = React.createContext();

export function useError() {
	return useContext(ErrorContext);
}
export function useErrorUpdate() {
	return useContext(ErrorUpdateContext);
}
export function useCheckRole() {
	return useContext(CheckRoleContext);
}

export function ErrorProvider({ children }) {
	const [error, setError] = useState(null);
	const currentUser = useCurrentUser();
	const navigate = useNavigate();

	function updateError(title, message) {
		logContext(CLASSNAME, 'info', `############# updateError ############### ${title} ${message}`);
		setError({ title, message });
		navigate('/' + ENUM_ROUTES.ERROR)
	}

	const checkRole = useCallback(async (roleToCheck) => {
		if (currentUser && !currentUser.roles.find((e) => e.alias === roleToCheck)) {
			updateError('Keine Berechtigung!', 'Sie haben keine Berechtigung für diesen Bereich!');
			return false;
		}
		return true;
	}, [currentUser])

	return (
		<CheckRoleContext.Provider value={checkRole}>
			<ErrorContext.Provider value={error ? error : { title: 'Fehlerinformation', message: null }}>
				<ErrorUpdateContext.Provider value={updateError}>
					{children}
				</ErrorUpdateContext.Provider>
			</ErrorContext.Provider>
		</CheckRoleContext.Provider>
	)
}

export const ERROR_RESOURCENOTFOUND = {
	title: 'Zugriff verweigert', message: 'Die von Ihnen gewünschte Ressource kann nicht geöffnet werden. Dies kann eine <br/>oder mehrere der folgenden Gründe haben:' +
		'<ul><li>Sie haben nicht die erforderlichen Berechtigungen</li>' +
		'<li>Die Berechtigungen der Ressource haben sich geändert</li>' +
		'Bei Rückfragen wenden Sie sich bitte an ihre Administration.'
}