import React, { useContext, useState, useEffect } from "react";
import { getData } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { OrgfunctionProvider } from "./OrgfunctionContext";
import { RoleProvider } from "./RoleContext";

const providername = 'QualificationProvider';
const servicename = 'qualifications';
const QualificationContext = React.createContext();

export function useQualificationList() {
	return useContext(QualificationContext);
}

export function QualificationProvider({ children }) {
	const [qualificationList, setQualificationList] = useState([]);

	useEffect(() => {
		logContext(providername, 'info', `########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			logContext(providername, 'info', `########### ${providername} list: ${JSON.stringify(_list)}`)
			setQualificationList(_list);
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}, [])

	return (
		<QualificationContext.Provider value={qualificationList}>
			<OrgfunctionProvider>
				<RoleProvider>
					{children}
				</RoleProvider>
			</OrgfunctionProvider>
		</QualificationContext.Provider>
	)
}