import React, { useContext, useEffect, useState } from "react";
import client, { getData, updateServiceListener } from "../feathers";
import { LoggerContext, logContext } from "../Utils/logger";
import { SocketErrorProvider } from "./SocketErrorContext";
import * as Sentry from "@sentry/react";

const providername = 'CurrentUserProvider'
const servicename = 'users';
const listenernames = ['created', 'patched'];

const CurrentUserContext = React.createContext();
const CurrentUserUpdateContext = React.createContext();
const CurrentUserIsSuperAdminContext = React.createContext();
const CurrentUserIsAdminContext = React.createContext();
const CurrentUserIsReaderContext = React.createContext();

export function useCurrentUser() {
	return useContext(CurrentUserContext);
}
export function useCurrentUserUpdate() {
	return useContext(CurrentUserUpdateContext);
}
export function useCurrentUserIsSuperAdmin() {
	return useContext(CurrentUserIsSuperAdminContext);
}
export function useCurrentUserIsAdmin() {
	return useContext(CurrentUserIsAdminContext);
}
export function useCurrentUserIsReader() {
	return useContext(CurrentUserIsReaderContext);
}

export function CurrentUserProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isReader, setIsReader] = useState(false);

	async function setUser(user) {
		if (user) {
			const { id, isAdmin, displayname, roles, permissions, forceChangePassword, email } = user;
			setIsSuperAdmin(user.isAdmin === 1);
			setIsAdmin(user.permissions === 'ADMIN');
			setIsReader(user.permissions === 'READER');
			Sentry.setUser({ email });
			Sentry.setTag('permission', permissions);
			logContext(providername, 'info', 'set currentUser: ', user)
		} else {
			setIsSuperAdmin(false);
			setIsAdmin(false);
			setIsReader(true);
			Sentry.setUser(null);
			Sentry.setTag('permission', '');
		}
		setCurrentUser(user);
	}

	return (

		<CurrentUserContext.Provider value={currentUser}>
			<CurrentUserIsSuperAdminContext.Provider value={isSuperAdmin}>
				<CurrentUserIsAdminContext.Provider value={isAdmin}>
					<CurrentUserIsReaderContext.Provider value={isReader}>
						<CurrentUserUpdateContext.Provider value={setUser}>
							<SocketErrorProvider>
								{children}
							</SocketErrorProvider>
						</CurrentUserUpdateContext.Provider>
					</CurrentUserIsReaderContext.Provider>
				</CurrentUserIsAdminContext.Provider>
			</CurrentUserIsSuperAdminContext.Provider>
		</CurrentUserContext.Provider>

	)
}