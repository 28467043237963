import { Button } from "primereact/button";
import { StyleClass } from 'primereact/styleclass';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ENUM_ICONS } from "../Components/Icons";
import { getData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { updateLastAction } from '../Persistence/LoginContext';
import logger from '../Utils/logger';
import { ENUM_ROUTES, ROUTES } from "./Routes";
import { Sidebar } from './Sidebar';
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";

const HEADER_HEIGHT = 50;

export const NavigationPage = () => {
	const currentUser = useCurrentUser();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const btnRef6 = useRef(null);
	const [showSidebarLabel, setShowSidebarLabel] = useState(false);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (currentUser) {
			setMounted(true)
		}
	}, [currentUser])

	useEffect(() => {
		var handlekeyDown = window.addEventListener('keydown', (event) => { updateLastAction(); });
		var handleClick = window.addEventListener('click', (event) => { updateLastAction(); });
		return () => { logger.debug('clear Inteval timeIntervalExpire & eventListener'); window.removeEventListener('click', handleClick); window.removeEventListener('keydown', handlekeyDown); }
	}, []);

	const onSidebarClick = (route, icon, label) => {
		updateBreadcrumbs({ home: { icon }, items: [{ label, route }] })
	}

	return (!mounted ? '' :
		<div className="min-h-screen flex relative lg:static">
			<div id="app-sidebar" className="app-sidebar h-screen flex-shrink-0  left-0 top-0 z-1 w-4rem lg:w-auto select-none">
				<div className={'hidden lg:block h-full'} onMouseEnter={() => { setShowSidebarLabel(true) }} onMouseLeave={() => { setShowSidebarLabel(false) }}>
					<Sidebar onSidebarClick={onSidebarClick} showSidebarLabel={showSidebarLabel} />
				</div>
				<div className={'block lg:hidden h-full'} >
					<Sidebar onSidebarClick={onSidebarClick} showSidebarLabel={false} />
				</div>
			</div>
			<div className="min-h-screen flex flex-column relative flex-auto">
				<div className={"app-header align-items-center px-3 relative lg:static"} style={{ height: HEADER_HEIGHT + 'px' }}>
					<div className="flex ">
						<StyleClass nodeRef={btnRef6} selector="#app-sidebar" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
							<a ref={btnRef6} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
								<i className="fa fa-bars text-4xl"></i>
							</a>
						</StyleClass>
					</div>
					<div className='flex justify-content-center align-items-center h-full'>
						<div className='font-medium'>
							Willkommen {currentUser.displayname}
						</div>
					</div>
				</div>
				<div className="flex justify-content-center m-0 flex-auto" >
					<div className="pl-2 m-0 surface-border align-content-center overflow-hidden w-full" style={{ height: 'calc(100vh - ' + HEADER_HEIGHT + 'px)' }}>
						<Outlet />
					</div>
				</div>
			</div>
		</div>

	);
}