import React, { useContext, useState, useEffect } from "react";
import { getData } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const providername = 'RoleProvider';
const servicename = ENUM_SERVICES.ROLES
const RoleContext = React.createContext();

export function useRoleList() {
	return useContext(RoleContext);
}

export function RoleProvider({ children }) {
	const [list, setList] = useState([]);
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### RoleProvider useEffect');
			getData(servicename).then((_list) => {
				logContext(providername, 'info', `########### ${providername} list: ${JSON.stringify(_list)}`)
				setList(_list);
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		}
	}, [mounted])

	return (
		<RoleContext.Provider value={list}>
			{children}
		</RoleContext.Provider>
	)
}