import { useState, useEffect } from 'react'
import { PrimeIcons } from 'primereact/api';
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { NavigationEntry } from "./NavigationEntry"
import { ENUM_ROUTES } from "./Routes"
import { logout } from "../feathers";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS } from "../Components/Icons";
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { ENUM_ROLES } from '../Enums/ENUM_ROLES';

export const Sidebar = ({ onSidebarClick, showSidebarLabel }) => {
	const currentUser = useCurrentUser();

	function ErrorFallback({ error, resetErrorBoundary }) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		)
	}

	const onLogout = () => {
		logout(currentUser)
		if (window.location.pathname !== '/') {
			window.location.replace(window.location.protocol + '//' + window.location.host);
		}
	}

	return (
		<div className="flex flex-column h-full" >
			<div className="flex ml-4 pt-3 overflow-hidden" style={{ height: '60px', width: '30px' }}>
				<img src="../icons/170719_CBL_Logo.png" style={{ height: '30px' }} />
			</div>
			<div className="mt-3">
				<ul className="list-none p-0 m-0">
					{!currentUser.isEmployee ? '' : <NavigationEntry awesomeIcon={ENUM_AWESOMEICON.STATUS} label='Mein Status' route={ENUM_ROUTES.EMPLOYEESTATUS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />}
					{!currentUser.isEmployee ? '' : <NavigationEntry awesomeIcon={ENUM_AWESOMEICON.USER} label='Mein Profil' route={ENUM_ROUTES.USERPROFILE} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />}
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.FORCEMONITOR} role={ENUM_ROLES.FORCEMONITOR} label='Kräfteübersicht' route={ENUM_ROUTES.FORCEMONITOR} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.GRAPH24H} role={ENUM_ROLES.GRAPH24H} label='24h Auswertung' route={ENUM_ROUTES.GRAPH24H} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.CALENDAR} role={ENUM_ROLES.CALENDAREVD} label='EVD-Kalender' route={ENUM_ROUTES.CALENDAREVD} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.GUARDBOOKS} role={ENUM_ROLES.GUARDBOOKS} label='Wachbücher' route={ENUM_ROUTES.GUARDBOOKS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.USERADMINISTRATION} role={ENUM_ROLES.USERS} label='Benutzerverwaltung' route={ENUM_ROUTES.USERS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
				</ul>
			</div>
			<div className="mt-auto">
				<a className="p-ripple my-3 gap-2 flex flex-row align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors" onClick={onLogout}>
					<i className="flex pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-red-800"></i>
					<span className={(showSidebarLabel ? 'flex inline' : 'hidden') + " font-medium"}>Logout</span>
					<Ripple />
				</a>
			</div>
		</div>
	)

}
