import { useCompaniesList } from "../Persistence/CompaniesContext";
import { useGraph24h, useGraph24hUpdate } from "../Persistence/Graph24hContext";
import { Calendar } from 'primereact/calendar';

const COLUMN_WIDTH = 40
const GRAPH_HEIGHT = 400;
const HEADER_HEIGHT = 30;
const yBase = GRAPH_HEIGHT + HEADER_HEIGHT;

export const Graph24h = () => {
	const graph24h = useGraph24h()
	const graph24hUpdate = useGraph24hUpdate();
	const companies = useCompaniesList();

	const renderCompanies = () => {
		return companies.map((company) => {
			return <div className='flex p-2 border-1' style={{ background: company.bgColor }}>{company.display}</div>
		})
	}

	const horizonalLine = (i, totalValue) => {
		return (<>
			{i !== 23 ? '' : <line className='horizontalline' x1={(i + 1) * COLUMN_WIDTH + 10} y1='0' x2={((i + 1) * COLUMN_WIDTH + 10)} y2={GRAPH_HEIGHT + HEADER_HEIGHT + 10} />}
			<line className='horizontalline' x1={i * COLUMN_WIDTH + 10} y1='0' x2={(i * COLUMN_WIDTH + 10)} y2={GRAPH_HEIGHT + HEADER_HEIGHT + 10} />
			{i < 24 ? <text className='totalvalues' x={(i * COLUMN_WIDTH + (totalValue < 10 ? 5 : 0) + COLUMN_WIDTH / 2)} y='20'>{totalValue}</text> : ''}
			<text className='xaxis' x={(i * COLUMN_WIDTH + (i < 10 ? 5 : 0))} y={(GRAPH_HEIGHT + HEADER_HEIGHT + 25)}>{i}</text >
			{i !== 23 ? '' : <text className='xaxis' x={((i + 1) * COLUMN_WIDTH + (i < 10 ? 5 : 0))} y={(GRAPH_HEIGHT + HEADER_HEIGHT + 25)}>{i + 1}</text >}
		</>
		)
	}

	const paintLines = () => {
		let i = -1;
		const result = graph24h.totalValues.map((e) => horizonalLine(++i, e))
		return result
	}

	const paintData = () => {
		let i = -1;
		const factor = graph24h.highestCount === 0 ? 1 : GRAPH_HEIGHT / (graph24h.highestCount + 1);
		const result = !graph24h ? '' : graph24h.totalValues.map((e) => {
			i++;
			let y = yBase;
			return graph24h['' + (i + 1)].values.map((f) => {
				const x = (i * COLUMN_WIDTH + 10 + COLUMN_WIDTH / 2);
				const height = factor * f.value;
				y -= height
				return f.value === 0 ? '' : <><rect style={{ fill: `${f.color}` }} x={`${x - 20}`} y={`${y}`} width='40' height={`${height}`} />
					<text style={{ fill: 'white' }} className='data' x={`${x}`} y={`${y + 15}`}>{f.value}</text></>

			})
		})
		return result;
	}

	return <div className='flex flex-column graph24h'>
		<div className='hidden lg:inline-flex justify-content-center w-full my-4'>
			{!graph24h ? '' : <Calendar value={new Date(graph24h.startDay)} onChange={(e) => graph24hUpdate(e.value)} dateFormat="dd.mm.yy" inline showWeek />}
		</div>
		<div className='flex justify-content-center w-full my-4 lg:hidden'>
			{!graph24h ? '' : <Calendar style={{ width: '100px' }} value={new Date(graph24h.startDay)} onChange={(e) => graph24hUpdate(e.value)} dateFormat="dd.mm.yy" showWeek />}
		</div>

		<div className='flex justify-content-center h-30rem w-full '>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				//viewBox="0 0 1000 480"
				stroke="currentColor"
				width='1000px'
				//height='100%'
				className='graph24h'
			>
				<line className="horizontalline" x1='10' y1='50' x2='970' y2='50' />
				{!graph24h ? '' : paintLines()}
				{!graph24h ? '' : paintData()}
				<line className='horizontalline' x1='10' y1={(GRAPH_HEIGHT + HEADER_HEIGHT)} x2='970' y2={GRAPH_HEIGHT + HEADER_HEIGHT} />
			</svg>
		</div>
		<div className='flex justify-content-center w-full gap-2'>
			{!companies ? '' : renderCompanies()}
		</div>
	</div>


}