import React, { useContext, useState, useEffect } from "react";
import client, { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext, logContext } from "../Utils/logger";
import { useCurrentUser, useCurrentUserUpdate } from "./CurrentUserContext";

const UserContext = React.createContext();
const UserSelectionContext = React.createContext();
const UserResponsiblesContext = React.createContext();

const servicename = 'users';
const listenernames = ['created', 'patched'];
const providername = 'UserProvider';

export function useUserList() {
	return useContext(UserContext);
}
export function useUserSelectionList() {
	return useContext(UserSelectionContext);
}

export function useUserResponsibleList() {
	return useContext(UserResponsiblesContext);
}

export const sortUserList = (list) => {
	const sortedList = !list ? [] : list.sort((a, b) => {
		let x = (a.lastname + a.firstname).toLowerCase();
		let y = (b.lastname + b.firstname).toLowerCase();
		if (x < y) { return -1; }
		if (x > y) { return 1; }
		return 0;
	})
	sortedList.forEach(e => {
		e.nameInList = e.lastname + ', ' + e.firstname
	});
	return sortedList;
}

export function UserProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState();
	const [listResponsibles, setListResponsibles] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false);
	const currentUser = useCurrentUser();
	const currentUserUpdate = useCurrentUserUpdate();

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false);
			const { id } = listItemChanged;
			if (listItemChanged.removed) {
				setListSelection(listSelection.filter((entry) => entry.id !== id))
				setListResponsibles(listResponsibles.filter((entry) => entry.id !== id))
			} else {
				logContext(providername, 'info', 'user changed id:' + listItemChanged.id + ' currentUser?:' + (id === currentUser.id))
				if (id === currentUser.id) {
					currentUserUpdate(listItemChanged)
				}
				setListSelection(sortUserList(list))
			}

			setListItemChanged(null);
		}
	}, [listItemChanged, list, listSelection])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			findData(servicename, { isAdmin: { $ne: 1 }, id: { $gt: 0 }, $plain: 1 }).then((_list) => {
				setList(sortUserList(_list));
				const listSelection = sortUserList(_list.filter(e => e.active === 1))
				setListSelection(listSelection)
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length} active: ${listSelection.length}`, _list.find(e => e.id === 0));
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { logContext(providername, 'info', `########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<UserContext.Provider value={list}>
			<UserSelectionContext.Provider value={listSelection}>
				{children}
			</UserSelectionContext.Provider>
		</UserContext.Provider>
	)
}