import { FilterService, FilterMatchMode, SortOrder } from 'primereact/api';
import React, { useContext, useEffect, useState } from "react";
import { useCurrentUser } from "./CurrentUserContext";
import { logContext } from '../Utils/logger';
import { patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { parseISO } from 'date-fns';

FilterService.register("multiIds", (value, filter) => { return !filter ? true : value.filter(element => filter.includes(element.id)).length > 0 });

const CLASSNAME = 'DataViewFilterProvider';

const DataViewMountedContext = React.createContext();
const DataViewFilterContext = React.createContext();
const DataViewSortContext = React.createContext();
const DataViewFilterUpdateContext = React.createContext();
const DataViewSortUpdateContext = React.createContext();
const DataViewPaginationContext = React.createContext();
const DataViewPaginationUpdateContext = React.createContext();

export const ENUM_DATAVIEWS = {
	REQUESTDISTRIBUTIONS_USERMATRIX: 'requestdistributionsusermatrix',
	REQUESTDISTRIBUTIONSARCHIV: 'requestdistributionsarchiv',
	REQUESTDISTRIBUTIONS: 'requestdistributions',
	REMINDERS: 'reminders',
	EAACASES: 'eaacases',
	COMPANIES: 'companies',
	JOBOPENINGS: 'jobopenings',
	PROVIDERACTIVITIES: 'provideractivities',
	USERADMINISTRATION: 'useradministration',
	CASERQUESTTYPES: 'caserequesttypes',
	REGIONS: 'regions',
	ORGANIZATIONS: 'organizations',
	TASKAREAS: 'taskareas',
	ASSOCIATEDCONTACTS: 'associatedcontacts'
}

export function useDataViewMounted() {
	return useContext(DataViewMountedContext);
}
export function useDataViewFilter() {
	return useContext(DataViewFilterContext);
}
export function useDataViewFilterUpdate() {
	return useContext(DataViewFilterUpdateContext);
}
export function useDataViewSort() {
	return useContext(DataViewSortContext);
}
export function useDataViewSortUpdate() {
	return useContext(DataViewSortUpdateContext);
}
export function useDataViewPagination() {
	return useContext(DataViewPaginationContext);
}
export function useDataViewPaginationUpdate() {
	return useContext(DataViewPaginationUpdateContext);
}

export function DataViewFilterProvider({ children }) {
	const currentUser = useCurrentUser();
	const [mounted, setMounted] = useState(false);

	const [dataViewFilter, setDataViewFilter] = useState({
		eaacases: DEFAULT_EAACASES_FILTER,
		companies: DEFAULT_COMPANIES_FILTER,
		jobopenings: DEFAULT_JOBOPENINGS_FILTER,
		provideractivities: DEFAULT_PROVIDERACTIVITIES_FILTER,
		requestdistributions: DEFAULT_REQUESTDISTRIBUTIONS_FILTER,
		requestdistributionsarchiv: DEFAULT_REQUESTDISTRIBUTIONSARCHIV_FILTER,
		requestdistributionsusermatrix: DEFAULT_REQUESTDISTRIBUTIONS_USERMATRIX_FILTER,
		caserequesttypes: DEFAULT_CASEREQUESTTYPES_FILTER,
		organizations: DEFAULT_ORGANIZATIONS_FILTER,
		regions: DEFAULT_REGIONS_FILTER,
		taskareas: DEFAULT_TASKAREAS_FILTER,
		useradministration: DEFAULT_USERADMINISTRATION_FILTER,
		reminders: DEFAULT_REMINDERS_FILTER,
		associatedcontacts: DEFAULT_ASSOCIATEDCONTACTS_FILTER
	});

	const [dataViewSort, setDataViewSort] = useState({
		eaacases: [],
		companies: [],
		jobopenings: [],
		provideractivities: [],
		requestdistributions: [],
		requestdistributionsarchiv: [],
		requestdistributionsusermatrix: [],
		caserequesttypes: [],
		organizations: [{ sortField: null, sortOrder: null }],
		regions: [{ sortField: 'name', sortOrder: SortOrder.ASC }],
		taskareas: [],
		useradministration: [],
		reminders: [],
		associatedcontacts: []
	});

	const [dataViewPagination, setDataViewPagination] = useState({
		eaacases: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		companies: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		jobopenings: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		provideractivities: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		requestdistributions: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		requestdistributionsarchiv: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		requestdistributionsusermatrix: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		caserequesttypes: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		organizations: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		regions: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		taskareas: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		useradministration: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		reminders: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		associatedcontacts: { paginationFirst: 0, paginationRows: 15, currentPage: 0 }
	});

	const setDataViewValues = (data, settings) => {
		const settingKeys = Object.keys(settings);
		for (let dataKey of settingKeys) {
			for (let key of Object.keys(settings[dataKey])) {
				const entry = settings[dataKey][key];
				if (entry.instance === 'DATE' && entry.maxDate) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].maxDate)
				}
				if (entry.instance === 'DATE' && entry.value) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].value)
				}
			}
		}

		const dataKeys = Object.keys(data);

		for (let dataKey of dataKeys) {
			if (settingKeys.includes(dataKey)) {
				data[dataKey] = settings[dataKey].maxDate ? { ...settings[dataKey], maxDate: new Date() } : settings[dataKey]
			}
		}
	}

	useEffect(() => {
		if (currentUser.settings && currentUser.settings.dataViewFilter) {
			setDataViewValues(dataViewFilter, currentUser.settings.dataViewFilter);
			setDataViewValues(dataViewSort, currentUser.settings.dataViewSort);
			setDataViewValues(dataViewPagination, currentUser.settings.dataViewPagination);
		}
		setMounted(true);

	}, [currentUser])

	const setFilter = (ENUM_DATAVIEWS, value) => {
		const filter = { ...dataViewFilter }
		filter[ENUM_DATAVIEWS] = value;
		logContext(CLASSNAME, 'info', 'setFilter: ' + ENUM_DATAVIEWS, value);
		if (currentUser.settings) {
			patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewFilter: filter, settingId: currentUser.id });
		}
		setDataViewFilter(filter)
	}
	const setSort = (ENUM_DATAVIEWS, value) => {
		const sortItem = { ...dataViewSort }
		sortItem[ENUM_DATAVIEWS] = value;
		logContext(CLASSNAME, 'info', 'setSort:' + ENUM_DATAVIEWS, sortItem[ENUM_DATAVIEWS])
		if (currentUser.settings) {
			patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewSort: sortItem, settingId: currentUser.id });
		}
		setDataViewSort(sortItem)
	}

	const setPagination = (ENUM_DATAVIEWS, value) => {
		const pagination = { ...dataViewPagination }
		pagination[ENUM_DATAVIEWS] = value;
		if (currentUser.settings) {
			patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, dataViewPagination: pagination, settingId: currentUser.id });
		}
		setDataViewPagination(pagination)
	}

	return (
		<DataViewMountedContext.Provider value={mounted}>
			<DataViewFilterContext.Provider value={dataViewFilter}>
				<DataViewSortContext.Provider value={dataViewSort}>
					<DataViewPaginationContext.Provider value={dataViewPagination}>
						<DataViewFilterUpdateContext.Provider value={setFilter}>
							<DataViewSortUpdateContext.Provider value={setSort}>
								<DataViewPaginationUpdateContext.Provider value={setPagination}>
									{children}
								</DataViewPaginationUpdateContext.Provider>
							</DataViewSortUpdateContext.Provider>
						</DataViewFilterUpdateContext.Provider>
					</DataViewPaginationContext.Provider>
				</DataViewSortContext.Provider>
			</DataViewFilterContext.Provider>
		</DataViewMountedContext.Provider>
	)
}

export const DEFAULT_COMPANIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_ASSOCIATEDCONTACTS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'organization': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Organisation suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'E-Mail suchen' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_EAACASES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: 'w-10rem' },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseStatusList', filterElement: 'multiTagFilterElement', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen', maxSelectedLabels: 4 },
}

export const DEFAULT_JOBOPENINGS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'location': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Ort suchen' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Stelle suchen' },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'JobOpeningStatusList', filterElement: 'multiTagFilterElement', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen', maxSelectedLabels: 4 },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
	'jobType': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'JobOpeningTypeList', filterElement: 'listFilter', filterElementClassName: 'w-11rem', filterPlaceholder: 'Typ wählen' },
	'millisecondsOpeningDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
}

export const DEFAULT_PROVIDERACTIVITIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'typeActivity': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'ProvideractivitiesTypeList', filterElement: 'listFilter', filterElementClassName: 'w-17rem', filterPlaceholder: 'Typ wählen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'millisecondsEnddate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: 'w-10rem' },
}

export const DEFAULT_REQUESTDISTRIBUTIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'subject': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Betreff suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Absender suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Aufgabenbereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
}
export const DEFAULT_REQUESTDISTRIBUTIONSARCHIV_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'subject': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Betreff suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Absender suchen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Aufgabenbereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
	'assigned': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: 'w-10rem' }
}
export const DEFAULT_REQUESTDISTRIBUTIONS_USERMATRIX_FILTER = {
	'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Benutzer*in suchen' },
	'regions': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Regionen wählen', filterListName: 'RegionsList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Regionen' },
	'taskareas': { value: null, matchMode: 'multiIds', filterPlaceholder: 'Aufgabenbereiche wählen', filterListName: 'TaskareasList', filterElement: 'multiSelectFilterElement', filterSelectedLabel: 'Aufgabenbereiche' },
}

export const DEFAULT_ORGANIZATIONS_FILTER = {
	'ignoreInStatistics': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'IgnoreInStatisticesList', filterElement: 'listFilter', filterElementClassName: '', filterPlaceholder: '' },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'type': { value: (null), matchMode: FilterMatchMode.EQUALS, filterListName: 'OrganizationTypesList', filterElement: 'listFilter', filterElementClassName: 'w-20rem', filterPlaceholder: 'Typ wählen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_CASEREQUESTTYPES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_REGIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'tagValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Tags suchen' },
	'postcodeValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'PLZs suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_TASKAREAS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'tagValues': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Tags suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_REMINDERS_FILTER = {
	'serviceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'ServiceList', filterElement: 'listFilter', filterElementClassName: 'w-4rem' },
	'referenceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Notiz suchen" },
}

export const DEFAULT_USERADMINISTRATION_FILTER = {
	'id': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Id suchen', filterElementClassName: 'w-3rem' },
	'adhocStatus.active': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'AdhocStatusList', filterElement: 'listFilter', filterElementClassName: 'w-5rem' },
	'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Benutzer*in suchen' },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'E-Mail suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterPlaceholder: 'Status wählen' },
	'roles': { value: null, matchMode: 'multiIds', filterListName: 'RolesList', filterElement: 'multiSelectFilterElement', filterPlaceholder: 'Rolle wählen' },
	'orgfunctions': { value: null, matchMode: 'multiIds', filterListName: 'OrgfunctionsList', filterElement: 'multiSelectFilterElement', filterPlaceholder: 'Funktion wählen' },
	'qualifications': { value: null, matchMode: 'multiIds', filterListName: 'QualificationsList', filterElement: 'multiSelectFilterElement', filterPlaceholder: 'Qualifikation wählen' },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'g26dateString': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Datum suchen' }
}
