import { endOfDay, endOfMonth, format, getDate, getDay, getMonth, isAfter, isBefore, parse, startOfDay, startOfMonth } from "date-fns";
import { useEffect, useState } from "react"
import { findData, getData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { Calendar } from "primereact/calendar";

export const Guardbooks = () => {
	const [mounted, setMounted] = useState(false)
	const [guardbookDate, setGuardbookDate] = useState(Date.now())
	const [viewDate, setViewDate] = useState(startOfMonth(Date.now()))
	const [pdf, setPdf] = useState()
	const [existingGuardbooks, setExistingGuarbooks] = useState([])

	useEffect(() => {
		setMounted(true)
		const startDay = addTimezone(startOfDay(Date.now()))
		setGuardbookDate(startDay)
	}, [])

	useEffect(() => {
		if (viewDate) {
			const startDay = startOfDay(startOfMonth(viewDate)).getTime();
			const endDay = endOfDay(endOfMonth(viewDate)).getTime();
			const query = { $select: ['id', 'date', 'sendDate'], $and: [{ date: { $gte: startDay } }, { date: { $lte: endDay } }] }
			findData(ENUM_SERVICES.GUARDBOOKS, query).then((existingGuardbooks) => {
				setExistingGuarbooks(existingGuardbooks.map(e => ({ id: e.id, date: format(parseInt(e.date), 'yyyy-MM-dd'), day: getDate(parseInt(e.date)), sent: parseInt(e.sendDate) !== 0 })))
			})
		}
	}, [viewDate])

	useEffect(() => {
		if (guardbookDate) {
			console.log('startDay:' + guardbookDate + ' ---- ' + format(guardbookDate, 'yyyy-MM-dd HH:mm:ss xxx'))
			getData(ENUM_SERVICES.GUARDBOOKS, guardbookDate).then((guardbook) => {
				setPdf(guardbook.pdf)
			})
		}
	}, [guardbookDate])

	const addTimezone = (date) => {
		const timezoneOffset = date.getTimezoneOffset() * 60 * 1000
		return date.getTime() - timezoneOffset
	}

	const dateTemplate = (date) => {
		date.date = addTimezone(parse(date.year + '-' + (date.month + 1) + '-' + date.day, 'yyyy-MM-dd', Date.now()))
		const existingGuardbook = existingGuardbooks.find(e => e.day === date.day);
		let color = 'white'
		if (!isBefore(date.date, viewDate) && !isAfter(date.date, endOfMonth(viewDate)) && !isAfter(date.date, Date.now())) {
			color = existingGuardbook ? (existingGuardbook.sent ? '#1dcbb3' : 'yellow') : 'red'
		}

		return (
			<div style={{ color }}>{date.day}</div>
		);
	}

	return !mounted ? '' : <div className='flex w-full h-full '>
		<div className='flex col-fixed align-items-start'>
			<div className='flex flex-column hidden lg:inline-flex justify-content-center w-full'>
				{!guardbookDate ? '' : <Calendar viewDate={viewDate} onViewDateChange={(e) => { setViewDate(e.value); console.log('monthChange', e) }} maxDate={new Date()} showButtonBar={true} value={guardbookDate} onChange={(e) => setGuardbookDate(addTimezone(e.value))} dateFormat="dd.mm.yy" inline showWeek dateTemplate={dateTemplate} />}

			</div>
			<div className='flex justify-content-center w-full lg:hidden'>
				{!guardbookDate ? '' : <Calendar maxDate={new Date()} showButtonBar={true} style={{ width: '100px' }} value={guardbookDate} onChange={(e) => setGuardbookDate(addTimezone(e.value))} dateFormat="dd.mm.yy" showWeek />}
			</div>

		</div>
		<div className="flex col flex-column">
			{!pdf ?
				<div className='flex h-full w-full pt-6 justify-content-center text-3xl'>
					Kein Wachbuch am {format(guardbookDate || Date.now(), 'dd.MM.yyyy')} vorhanden
				</div>
				:
				<embed
					src={'data:application/pdf;base64,' + pdf + '#toolbar=1&navpanes=1&scrollbar=1&view=FitH'}
					type="application/pdf"
					width="100%"
					height="100%"
				/>}
		</div>
	</div>
}