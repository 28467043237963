import { useForceMonitor } from "../Persistence/ForceMonitorContext"
import { useEffect, useState } from 'react'
import { getData } from "../feathers";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import { useCheckRole } from "../Persistence/ErrorContext";

const tableColumns = ['col-4', 'col-3', 'col-2', 'col-3']

export const ForceMonitor = () => {
	const forceMonitor = useForceMonitor();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderCommunication = (user) => {
		let counter = 0;
		return <div className='flex flex-column' key={'user-communication-' + counter++}>
			<div className='flex'>{user.phone}</div>
			<div className='flex'>{user.phoneBusiness}</div>
			<div className='flex'>{user.mobile}</div>
		</div>
	}

	const renderGroups = () => {
		let counter = 0
		return forceMonitor.groups.map((group) => {
			return <div className='flex groups flex flex-column w-full ' key={'group-' + counter++} >
				<div className='flex tablerow  w-full'>
					<div className='flex w-full header justify-content-center' >Aktuell anwesend {group.headline}: {group.users.length}</div>
				</div>
				<div className='flex w-full tablerow'>
					<div className={'flex tablecolumn ' + tableColumns[0]}><div className='tableheader'>Name</div></div>
					<div className={'flex tablecolumn ' + tableColumns[1]}><div className='tableheader'>Funktionen</div></div>
					<div className={'flex tablecolumn ' + tableColumns[2]}><div className='tableheader'>Firma</div></div>
					<div className={'flex tablecolumn ' + tableColumns[3]}><div className='tableheader'>Kontakt</div></div>
				</div>
				{group.users.map((user) => {
					return <div className='flex w-full ' key={'group-' + counter++}>
						<div className={'flex tablecolumn ' + tableColumns[0]}><div className='tableentry'>{user.displayname}</div></div>
						<div className={'flex tablecolumn ' + tableColumns[1]}><div className='tableentry'>{user.qualifications.map(e => e.alias).join(', ')}</div></div>
						<div className={'flex tablecolumn ' + tableColumns[2]}><div className='tableentry'>{user.company.name}</div></div>
						<div className={'flex tablecolumn ' + tableColumns[3]}><div className='tableentry'>{renderCommunication(user)}</div></div>
					</div>
				})}
			</div>
		})


	}
	const renderEvD = () => {
		return <div className='flex evd w-full '>
			<div className='flex col '>
				EvD Kontaktdaten
			</div>
			<div className='flex flex-column col-4 '>
				<div className='flex  '>{forceMonitor.evd ? forceMonitor.evd.displayname : ''}</div>
				<div className='flex  '>{forceMonitor.evd ? forceMonitor.evd.phone : ''}</div>
				<div className='flex  '>{forceMonitor.evd ? forceMonitor.evd.phoneBusiness : ''}</div>
				<div className='flex  '>{forceMonitor.evd ? forceMonitor.evd.mobile : ''}</div>
				<div className='flex  '>{forceMonitor.evd ? (forceMonitor.evd.company || {}).name : ''}</div>
			</div>
		</div>
	}

	const renderOrgFunctions = () => {
		let counter = 0
		return <div className='flex orgfunctions flex-column w-full'>

			<div className='flex header w-full justify-content-center' key={'orgfunction-header'}>Einsatz-Führungsfunktionen</div>

			{forceMonitor['operation_functions'].map((operationFunction) => {
				return <div className={'flex w-full orgfunction-entry ' + operationFunction.className} key={'orgfunction-' + counter++}>
					<div className='flex tablecolumn col-7'><div className='tableentry'>{operationFunction.label}</div></div>
					<div className='flex tablecolumn col'><div className='tableentry'>{operationFunction.value}</div></div>
				</div>
			})}
		</div>
	}


	return <div className="flex justify-content-center align-content-center md:p-4  w-full h-full overflow-auto">
		<div className='flex forcemanager justify-content-center '>
			<div className='flex flex-column w-full'>
				{(mounted && forceMonitor ? renderOrgFunctions() : '')}
				{(mounted && forceMonitor ? renderEvD() : '')}
				{(mounted && forceMonitor ? renderGroups() : '')}
			</div>
		</div>
	</div>

}
