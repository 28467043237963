import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENUM_ICONS } from "../Components/Icons";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { logDefault } from "../Utils/logger";


const BreadcrumbsContext = React.createContext();
const BreadcrumbsUpdateContext = React.createContext();
const BreadcrumbClickContext = React.createContext();
const BreadcrumbsAddContext = React.createContext();
const BreadcrumbsMinusContext = React.createContext();

export function useBreadcrumbs() {
	return useContext(BreadcrumbsContext);
}
export function useBreadcrumbsUpdate() {
	return useContext(BreadcrumbsUpdateContext);
}
export function useBreadcrumbsAdd() {
	return useContext(BreadcrumbsAddContext);
}
export function useBreadcrumbsMinus() {
	return useContext(BreadcrumbsMinusContext);
}
export function useBreadcrumbClick() {
	return useContext(BreadcrumbClickContext);
}

export function BreadcrumbsProvider({ children }) {
	const [breadcrumbs, setBreadcrumbs] = useState({ home: { icon: ENUM_ICONS.EMPLOYEESTATUS }, items: [{ label: 'Status', route: ENUM_ROUTES.EMPLOYEESTATUS }] });
	const navigate = useNavigate();

	function updateBreadcrumbs(breadcrumbs) {
		logDefault('BreadcrumbsProvider', 'info', 'updateBreadcrumbs', breadcrumbs)
		setBreadcrumbs(breadcrumbs)
		const index = breadcrumbs.items ? breadcrumbs.items.length - 1 : -1;
		if (index > -1) {
			const item = breadcrumbs.items[index];
			if (item) {
				navigate('/' + item.route)
			}
		}
	}

	function onBreadcrumbClick(route) {
		const items = []
		for (let entry of breadcrumbs.items) {
			items.push(entry)
			if (route === entry.route) {
				break;
			}
		}
		const _breadcrumbs = { ...breadcrumbs, items }
		setBreadcrumbs(_breadcrumbs)
		navigate('/' + route)
	}

	function addBreadcrumb(label, route) {
		const _breadcrumbs = { ...breadcrumbs }
		_breadcrumbs.items.push({ label, route })
		logDefault('BreadcrumbsProvider', 'info', 'addBreadcrumb', _breadcrumbs)
		setBreadcrumbs(_breadcrumbs);
		navigate('/' + route)
	}

	function minusBreadcrumb() {
		const _breadcrumbs = JSON.parse(JSON.stringify(breadcrumbs))
		if (_breadcrumbs.items.length > 1) {
			_breadcrumbs.items.pop();
		}
		const item = _breadcrumbs.items.pop()
		navigate('/' + item.route)
		return item;
	}



	return (
		<BreadcrumbsUpdateContext.Provider value={updateBreadcrumbs}>
			<BreadcrumbsMinusContext.Provider value={minusBreadcrumb}>
				<BreadcrumbsAddContext.Provider value={addBreadcrumb}>
					<BreadcrumbClickContext.Provider value={onBreadcrumbClick}>
						<BreadcrumbsContext.Provider value={breadcrumbs}>
							{children}
						</BreadcrumbsContext.Provider>
					</BreadcrumbClickContext.Provider>
				</BreadcrumbsAddContext.Provider>
			</BreadcrumbsMinusContext.Provider>
		</BreadcrumbsUpdateContext.Provider>
	)
}