import React, { useContext, useEffect, useState } from "react";
import { setupSocketErrorHandling } from "../feathers";
import logger from "../Utils/logger";
import { useCurrentUserUpdate } from "./CurrentUserContext";

const SocketErrorContext = React.createContext();
const SocketErrorUpdateContext = React.createContext();

export function useSocketError() {
	return useContext(SocketErrorContext);
}
export function useSocketErrorUpdate() {
	return useContext(SocketErrorUpdateContext);
}

export function SocketErrorProvider({ children }) {
	const [socketError, setSocketError] = useState(null);
	const setCurrentUser = useCurrentUserUpdate();

	function updateSocketError(_socketError) {
		logger.info(`############# updateSocketError ############### ${JSON.stringify(_socketError)}`);
		setSocketError(_socketError);
	}

	useEffect(() => {
		const errorHandler = (error) => {
			if (error !== "ping timeout" && error !== "transport close") {
				setCurrentUser(null);
				setSocketError("Achtung!!! Die Verbindung zum Server konnte nicht hergestellt werden!")
				console.error('error SocketErrorContext', error)
			}
		};
		const successHandler = () => {
			logger.info("successHandler Application:", socketError)
			if (socketError != null) {
				logger.info("RECONNECTED")
				setSocketError(null);
				window.location.reload();
			}
		};

		setupSocketErrorHandling(errorHandler, successHandler)
	}, [socketError, setCurrentUser]);

	return (
		<SocketErrorContext.Provider value={socketError}>
			<SocketErrorUpdateContext.Provider value={updateSocketError}>
				{children}
			</SocketErrorUpdateContext.Provider>
		</SocketErrorContext.Provider>
	)
}