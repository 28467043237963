import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export enum ENUM_ROUTES {
	ERROR = 'Fehler',
	EMPLOYEESTATUS = "Status",
	USERPROFILE = "Benutzerprofil",
	GUARDBOOKS = 'Wachbuecher',
	CALENDAREVD = 'EvD-Kalender',
	SETTINGS = 'Einstellungen',
	USERS = 'Benutzerverwaltung',
	GRAPH24H = 'Graph-24h',
	FORCEMONITOR = 'Kraefteuebersicht'
};

export const ROUTES = {
	ERROR: { label: 'Keine Berechtigung', icon: ENUM_ICONS.DEACTIVATE, route: ENUM_ROUTES.ERROR },
	EMPLOYEESTATUS: { label: 'Mein Status', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.EMPLOYEESTATUS },
	USERPROFILE: { label: 'Mein Profil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.USERPROFILE },
	CALENDAREVD: { label: 'EvD Kalender', icon: ENUM_ICONS.CALENDAR, route: ENUM_ROUTES.CALENDAREVD },
	USERS: { label: 'Benutzer*innnen', icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.USERS },
	GRAPH24H: { label: '24-h Auswertung', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.GRAPH24H },
	FORCEMONITOR: { label: 'Kräfteübersicht', icon: ENUM_ICONS.FORCEMONITOR, route: ENUM_ROUTES.FORCEMONITOR },
	GUARDBOOKS: { label: 'Wachbücher', icon: ENUM_ICONS.ADDRESS, route: ENUM_ROUTES.GUARDBOOKS, service: ENUM_SERVICES.GUARDBOOKS, labelNew: '' },
	SETTINGS: { label: 'Einstellungen', icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS, service: ENUM_SERVICES.ROLES, labelNew: '' },
}