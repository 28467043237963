import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '../Components/Dialogs/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { createPassword, getData, patchData } from '../feathers';
import { ChangePassword } from '../Login/ChangePassword';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useUserList } from "../Persistence/UserContext";
import { emptyUser, UserForm, validateUser } from './UserForm';
import { useFormik } from 'formik';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { TemplateDataView } from '../Components/TemplateDataView';
import { useErrorUpdate } from "../Persistence/ErrorContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import { format, addDays, isBefore } from "date-fns";

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.USERADMINISTRATION
export const UserAdministration = () => {
	const currentUser = useCurrentUser();
	const errorUpdate = useErrorUpdate();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const configurationsList = useConfigurationsList();
	const usersList = useUserList();
	const [user, setUser] = useState();
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);


	useEffect(() => {
		if (!currentUser.roles.find((e) => e.alias === ENUM_ROLES.USERS)) {
			errorUpdate('Keine Berechtigung!', 'Sie haben keine Berechtigung für diesen Bereich!')
		}
	}, [currentUser])

	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const getUser = async (id) => {
		await getData('users', id).then((user) => {
			user.permissions = configurationsList.RolesList.find((entry) => entry.alias === user.permissions)
			user.mobile = user.mobil || '';
			user.phone = user.phone || '';
			if (user.g26date) {
				user.g26date = new Date(parseInt('' + user.g26date))
			}
			formikUser.setValues(user)
			setUser(user);
		})
	}

	const handleSelectUser = async (id) => {
		await getUser(id)
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}
	const handleChangeUser = async (id) => {
		await getData('users', id).then((user) => {

		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser(rowData.id), disabled: currentUser.permissions === 'READER' },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in inaktiv setzen', icon: ENUM_ICONS.CROSS, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		} else {
			items.push({ label: 'Nutzer*in aktiv setzen', icon: ENUM_ICONS.CHECK, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		}
		if (currentUser.isAdmin) {
			items.push({ label: 'Identität annehmen', icon: ENUM_ICONS.USER, command: () => handleChangeUser(rowData.id) });
		}

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const patchUser = async () => {
		//formikUser.values.permissions = formikUser.values.permissions.alias
		const user = formikUser.values
		if (user.g26date) {
			user.g26date = new Date(user.g26date).getTime()
		}
		if (user.id) {
			delete user.password
		}
		await patchData('users', user).then((user) => {
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = async () => {
		formikUser.setValues({ ...emptyUser, password: await createPassword(false) })
		setDisplayUserDialog(true);
	}

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderEmail = (row) => {
		return <Link to='#' onClick={(e) => { window.location.href = `mailto:${row.email}`; e.preventDefault(); }}>{row.email}</Link>
	}

	const renderG26Date = (row) => {
		const yellowDate = addDays(new Date(), 60);
		const redDate = addDays(new Date(), 30);
		const date = !row.g26date ? null : parseInt('' + row.g26date);
		const color = isBefore(date, redDate) ? 'text-red-600' : (isBefore(date, yellowDate) ? 'text-yellow-500' : 'white')
		return !date ? '-' :
			<div className={color}>{row.g26dateString}</div>
	}

	const columns = [
		//{ field: "adhocStatus.active", sortable: true, filter: "adhocStatus.active", header: "Status", className: 'w-3rem', body: (row) => { return <Icon className='w-full justify-content-center' size={ENUM_AWESOMEICON_SIZE.X1} ENUM_AWESOMEICON={row.adhocStatus.active ? ENUM_AWESOMEICON.ONLINE : ENUM_AWESOMEICON.OFFLINE} /> } },
		{ field: "id", sortable: true, filter: "id", header: "Id", className: 'w-5rem', },
		{ field: "adhocStatus.start", sortable: true, header: "Status", className: 'w-7rem', body: (row) => (row.adhocStatus.start > 0 ? (format(parseInt(row.adhocStatus.start), 'dd.MM HH:mm') + (row.active ? ' (a)' : ' (i)')) : '-') },
		{ field: "displayname", sortable: true, filter: "displayname", header: "Name", className: '', },
		{ field: "company.name", sortable: true, filter: "company.name", header: "Unternehmen", className: '', body: null },
		{ field: "orgfunctions", sortable: true, filter: "orgfunctions", header: "Funktionen", className: 'w-10rem', body: (row) => row.orgfunctions.map(e => e.alias).join('; ') },
		{ field: "qualifications", sortable: true, filter: "qualifications", header: "Qualifikationen", className: 'w-10rem', body: (row) => row.qualifications.map(e => e.alias).join('; ') },
		{ field: "g26dateString", sortable: true, filter: "g26date", header: "G26 Prüfdatum", className: 'w-10rem', body: renderG26Date },
		{ field: "roles", sortable: true, filter: "roles", header: "Rollen", className: 'w-14rem', body: (row) => row.roles.map(e => <div>{e.display}</div>) },
		//{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		//{ field: "email", filter: true, sortable: true, header: "E-Mail", className: '', body: renderEmail },
		{ sortable: false, className: 'w-3rem', body: renderContextMenu },
	]

	return (
		<div className='mx-auto default-dataView'>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>

			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header={formikUser.values.id ? 'Nutzerdaten ändern' : 'Neue/r Nutzer*in hinzufügen'}
				message={formikUser.values.id ? 'Hier können sie den/die Nutzer*in: ' + formikUser.values.displayname + ' ändern.' : 'Hier können sie eine/n Nutzer*in neu anlegen'}
				setDisplayDialog={setDisplayUserDialog}
				displayRegionDialog={displayUserDialog}
			/>
			<>

				<div className="card hidden md:inline-flex w-full flex-column" >
					<div className="flex justify-content-end flex-wrap card-container gap-2 mx-4">
						<div className="flex align-self-center justify-content-left  my-2">
							<Button className='flex text-white' label='Nutzer*in hinzufügen' onClick={onClickNewUser} />
						</div>
						{isSuperAdmin ? <div className="flexalign-self-center justify-content-left  my-2">
							<Button className='flex  text-white' label='Fehler produzieren' onClick={() => { throw new Error('TEst Fehler') }} />
						</div> : ''}
					</div>
					<TemplateDataView ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} columns={columns} dataTableList={usersList} />
				</div>
			</>
		</div>
	)
}