import { useState, useEffect } from 'react';
import { format, toDate } from 'date-fns'
import { SelectButton } from 'primereact/selectbutton';
import { useCurrentUser, useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import './EmployeeStatus.scss'
import { useAdhocStatusCurrentUser } from '../Persistence/AdhocStatusContext';
import { patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';

export const EmployeeStatus = ({ employee }) => {

	const adhocStatusCurrentUser = useAdhocStatusCurrentUser();
	const [available, setAvailable] = useState(false);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (adhocStatusCurrentUser) {
			setAvailable(adhocStatusCurrentUser.start > 0)
			setMounted(true)
		}
	}, [adhocStatusCurrentUser])

	const handleClickButton = async (value) => {
		if (value != null) {
			await patchData(ENUM_SERVICES.ADHOCSTATUS, { id: adhocStatusCurrentUser.id });
		}
	}

	const renderButton = () => {
		return <div className="flex justify-content-center selectbutton">
			<SelectButton className={available ? 'selectbutton-true' : 'selectbutton-false'} value={available} options={[{ name: 'verfügbar', value: true }, { name: 'abwesend', value: false }]} onChange={(e) => handleClickButton(e.value)} optionValue={'value'} optionLabel={'name'} />
		</div>
	}

	const renderStatus = () => {
		if (!mounted) {
			return <h1>Ihr Status konnte nicht ermittelt werden.</h1>
		}
		return (
			<div className="flex employee-status justify-content-center align-items-center">
				<div className='flex flex-column employee-status-card justify-content-center'>
					<div className="employee-status-image text-2xl flex justify-content-center  w-full">
						{available ? 'Ich bin verfügbar' : 'Ich bin NICHT verfügbar'}
					</div>
					<div className="employee-status-image flex justify-content-center  w-full" onClick={() => handleClickButton()}>
						<img alt='' src={available ? '../icons/03_BG_status-ON.png' : '../icons/03_BG_status-OFF.png'} />
					</div>
					<div className="employee-status-image flex justify-content-center  w-full">
						{available && adhocStatusCurrentUser && adhocStatusCurrentUser.start > 0 ? 'Angemeldet seit: ' + format(adhocStatusCurrentUser.start, 'dd.MM.') + ' | ' + format(adhocStatusCurrentUser.start, 'HH:mm') : ''}
					</div>
					<div className="employee-status-image flex justify-content-center  w-full">
						{renderButton()}
					</div>
				</div>

			</div >)
	}

	return (
		<>
			<div className="flex h-full my-auto" >
				{renderStatus()}
			</div>
		</>
	)

}