export enum ENUM_ROLES {
	CONFIG = 'CONFIG',
	USERS = 'USERS',
	G26DATE = 'G26DATE',
	DRIVINGLICENSE = 'DRIVINGLICENSE',
	GRAPH24H = 'GRAPH24H',
	FORCEMONITOR = 'FORCEMONITOR',
	CALENDAREVD = 'CALENDAREVD',
	GUARDBOOKS = 'GUARDBOOKS',
	AGT = 'AGT'
};